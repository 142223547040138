import { TFunction } from 'i18next';
import { PatientName } from '@actimi/core-migration';
import { RecentActivties } from './../slices/patients-detail';
import { ITableItem } from 'src/models/dashboard-table';
import { InvitedPatientList } from 'src/models/patient-detail';
import { IRowData } from 'src/content/dashboards/Dashboard/utils';
import moment from 'moment';
import { PatientTabs } from 'src/content/dashboards/Patient/components/Charts/utils/ChartData';

export const formatActivites = (
  data: RecentActivties,
  patientName: PatientName,
  t: TFunction
) => {
  const { observation, schedule, tools, questionnaire, acuteWorkload } = data;
  const patientFullNameCapitalized = `${
    patientName.given.charAt(0).toUpperCase() + patientName.given.slice(1)
  } ${
    patientName.family.charAt(0).toUpperCase() + patientName.family.slice(1)
  }`;

  const formatted = {
    observation: {
      infoText:
        observation?.value?.Quantity?.code !== '8302-2' &&
        t('entered-mood', {
          name: patientFullNameCapitalized
        }),
      date:
        observation?.value?.Quantity?.code !== '8302-2' &&
        observation?.meta?.createdAt
    },
    questionnaire: {
      infoText: t('completed-questionnaire', {
        name: patientFullNameCapitalized
      }),
      date: questionnaire?.meta?.createdAt
    },
    acuteWorkload: {
      infoText: t('completed-exercise', {
        name: patientFullNameCapitalized
      }),
      date: acuteWorkload?.meta?.createdAt
    },
    schedule: {
      infoText: t('update-training-schedule', {
        name: patientFullNameCapitalized
      }),
      //FIXIT:Training schedules type should be include meta
      // @ts-ignore
      date: schedule?.meta?.lastUpdated
    },
    tools: {
      infoText: t('update-training-tools', {
        name: patientFullNameCapitalized
      }),
      date: tools?.meta?.lastUpdated
    }
  };

  return formatted;
};

export const groupPatient = (
  tableData,
  tableInactiveData,
  filter
): IRowData[] => {
  const oneWeekAgo = moment().subtract(7, 'days');
  switch (filter) {
    case 'Invited Patients':
      return tableInactiveData || [];
    case 'Active Patients':
      return (
        tableData.filter(
          (x) =>
            !moment(x.lastupdate)?.isBefore(oneWeekAgo) &&
            x.careplanstatus !== 'completed'
        ) || []
      );
    case 'Inactive Patients':
      return (
        tableData.filter(
          (x) =>
            moment(x.lastupdate)?.isBefore(oneWeekAgo) &&
            x.careplanstatus !== 'completed'
        ) || []
      );
    case 'Completed Patients':
      return tableData.filter((x) => x.careplanstatus === 'completed') || [];
    default:
      return [...tableData, ...tableInactiveData] || [];
  }
};

export const patientDetailTabs = (t: TFunction) => {
  return [
    { value: PatientTabs.WEEKLY_TRENDS, label: t('Weekly') },
    { value: PatientTabs.MONTHLY_TRENDS, label: t('Monthly') }
  ];
};

export const localizableBodyParts = (part: string, t: TFunction): string => {
  switch (part) {
    case 'Left Foot':
      return t('left-foot');
    case 'Right Foot':
      return t('right-foot');
    case 'Left Lower Leg':
      return t('left-lower-leg');
    case 'Right Lower Leg':
      return t('right-lower-leg');
    case 'Left Knee':
      return t('left-knee');
    case 'Right Knee':
      return t('right-knee');
    case 'Left Upper Leg':
      return t('left-upper-leg');
    case 'Right Upper Leg':
      return t('right-upper-leg');
    case 'Left Hand':
      return t('left-hand');
    case 'Right Hand':
      return t('right-hand');
    case 'Left Abdomen':
      return t('left-abdomen');
    case 'Right Abdomen':
      return t('right-abdomen');
    case 'Left Chest':
      return t('left-chest');
    case 'Right Chest':
      return t('right-chest');
    case 'Left Fore Arm':
      return t('left-fore-arm');
    case 'Right Fore Arm':
      return t('right-fore-arm');
    case 'Left Arm':
      return t('left-arm');
    case 'Right Arm':
      return t('right-arm');
    case 'Left Shoulder':
      return t('left-shoulder');
    case 'Right Shoulder':
      return t('right-shoulder');
    case 'Neck':
      return t('neck');
    case 'Left Head':
      return t('left-head');
    case 'Right Head':
      return t('right-head');
    default:
      t('Unkown Type');
  }
};
