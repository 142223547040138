/* eslint-disable react-hooks/exhaustive-deps */
import { Box, BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import RoundedTabs from '../Base/RoundedTabs';
declare type ITabsProps = {
  tabs: Array<{ value: string; label: string }>;
  children: (tabValue: string) => JSX.Element;
  panelProps?: BoxProps;
  forcedIndex?: number;
  disabled?: boolean;
};

const TabsContainer: React.FC<ITabsProps> = ({
  tabs,
  children,
  panelProps,
  forcedIndex,
  disabled
}) => {
  const [value, setValue] = React.useState(tabs[0].value);

  useEffect(() => {
    if (typeof forcedIndex === 'number') setValue(tabs[forcedIndex].value);
  }, [forcedIndex]);

  return (
    <React.Fragment>
      <Box
        borderTop={'1px solid rgba(23, 37, 86, 0.1)'}
        borderBottom={'1px solid rgba(23, 37, 86, 0.1)'}
        bgcolor="#F7F9FD"
        p="12.5px"
        pl="50px"
        sx={{ width: '100%' }}
      >
        <RoundedTabs
          tabData={tabs}
          value={value}
          disabled={disabled}
          onChange={(e, v) => setValue(v)}
        />
      </Box>
      <Box
        sx={
          value !== 'patient-agreement' && {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
        {...panelProps}
      >
        {children(value)}
      </Box>
    </React.Fragment>
  );
};

export default TabsContainer;
