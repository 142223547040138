import {
  Extension,
  Questionnaire,
  QuestionnaireItem
} from '@actimi/core-migration';
import _ from 'lodash';
import {
  convertIntensityToNumericValue,
  TypeUrlName
} from 'src/models/exercise-plan/ExercisePlan';
import { linkIds } from './../content/dashboards/Patient/components/ConditionsDrawer';
import { QuestionnaireResponseItem } from '@actimi/core-migration';
export const getPainScaleText = (value: number): string => {
  if (value >= 0 && value < 1) {
    return value + ' - No Pain';
  }
  if (value >= 1 && value < 3) {
    return value + ' - Mild Pain';
  }
  if (value >= 3 && value < 5) {
    return value + ' - Moderate Pain';
  }
  if (value >= 5 && value < 7) {
    return value + ' - Severe Pain';
  }
  if (value >= 7 && value <= 9) {
    return value + ' - Very Severe Pain';
  }
  if (value === 10) {
    return value + ' - Worst Pain';
  }
};

export const formatTheraphyGoalAnswer = (linkId: string): string => {
  switch (linkId) {
    case '/D-!-therapy':
      return 'Reduce Pain';

    case '/D-2-activty-therapy':
      return 'Improve Mobility';

    case '/D00':
      return 'Improve Flexibility';

    case '/D001':
      return 'Physically Resillent';

    case '/D002':
      return 'Improve Posture';

    case '/D003':
      return 'Prevent Sports Injuries';
  }
};

export const formatActivityGoalAnswer = (linkId: string): string => {
  switch (linkId) {
    case '/D00-activity':
      return 'Return to Activity';

    case '/D001-activity':
      return 'Sports for Feel Better';

    case '/D002-activity':
      return 'Frequently Activity';

    case '/D003-activity':
      return 'Competitive Sports';
  }
};

export const findPatientTarget = (
  questionnaireResponseItem: QuestionnaireResponseItem[]
) => {
  const target = questionnaireResponseItem?.find(
    (item) => item?.linkId === linkIds.target
  );
  const selectedLinkIds = target?.item
    .filter((x) => x?.answer?.[0]?.value?.boolean === true)
    .map((x) => x?.linkId);

  const calculation = selectedLinkIds?.map((linkId) => {
    return linkId.includes('nondirectional')
      ? 1
      : linkId.includes('monodirectional')
      ? 2
      : linkId.includes('bidirectional')
      ? 3
      : 4;
  });

  return selectedLinkIds?.length > 0
    ? numberToTarget(Math.max(...calculation))
    : 'No Target';
};

export const numberToTarget = (number: number): string => {
  switch (number) {
    case 1:
      return 'Non-Directional';

    case 2:
      return 'Mono-Directional';

    case 3:
      return 'Bi-Directional';

    case 4:
      return 'Multi-Directional';
  }
};

export const findPatientDesiredActivities = (
  questionnaireResponseItem: QuestionnaireResponseItem[]
) => {
  const target = questionnaireResponseItem?.find(
    (item) => item?.linkId === linkIds.target
  );
  const selectedActivities = target?.item
    .filter((x) => x?.answer?.[0]?.value?.boolean === true)
    .map((x) => x?.text);

  return selectedActivities;
};

export const calculateExerciseIntensity = (exercises: Questionnaire[]) => {
  let sampleArr = [];

  exercises.map((questionnaire) => {
    questionnaire.item[0].extension.map((ext, index) => {
      if (ext.url === TypeUrlName.Level) {
        return sampleArr.push(convertIntensityToNumericValue(ext.valueString));
      }
    });
  });

  return sampleArr;
};

export const questionnaireMappingExercise = (
  questionnaireData: QuestionnaireItem,
  url
) => {
  return String(
    questionnaireData.extension

      .map((item) => {
        if (item.url === url) {
          return String(item.valueString);
        }
      })
      .filter((x) => x !== undefined)
  );
};

export const filterPublicExercise = (
  exercises: Questionnaire[],
  practitionerId: string
): Questionnaire[] => {
  return exercises?.filter(
    (a) =>
      a?.item
        ?.find((b) => b?.type === 'display')
        .extension.find(
          (c) =>
            c.url ===
            'http://extension.actimi.com/questionnaire-exercise-database-type'
        )?.valueString === 'Public' || a?.publisher === practitionerId
  );
};

export const getFilteredQuestionnaireQuery = (
  filterValues: string[],
  language: string
) => {
  const formatParameters = filterValues
    .filter((x) => x !== undefined)
    .map((item, index) => {
      return '&item-extension-value='.concat(filterValues[index]);
    });

  const combinedUrl =
    `/Questionnaire?title=mifysio-exercise&_sort=-createdAt&status=active&language=de&_result=array&_page=1`.concat(
      ...formatParameters
    );

  return combinedUrl;
};

export const parseDisplayQuestionniareItem = (data: Questionnaire[]) => {
  const parsedQuestionnaireItems = data.map((x: Questionnaire) => {
    return x.item?.find((x) => x.type === 'display');
  });
  return parsedQuestionnaireItems;
};

export const prepareDataByLanguage = (
  data: Questionnaire[],
  language: string
) => {
  const parsedRawQuestionnaire = parseDisplayQuestionniareItem(data);
  const filteredName = parsedRawQuestionnaire.map((x: QuestionnaireItem) => {
    return x.extension?.filter((x) =>
      x.url.startsWith(extensionPathByLanguage(language).name)
    );
  });
  const filteredInstructions = parsedRawQuestionnaire.map(
    (x: QuestionnaireItem) => {
      return x?.extension
        ?.find(
          (x) => x.url === 'http://extension.actimi.com/exercise-assistant'
        )
        ?.extension?.filter((x) =>
          x.url.startsWith(extensionPathByLanguage(language).instructions)
        );
    }
  );

  const filteredExtensions = parsedRawQuestionnaire.map(
    (x: QuestionnaireItem) => {
      return (x?.extension as Extension[]).filter(
        (x: Extension) =>
          !x.url.startsWith(
            'http://extension.actimi.com/questionnaire-navigation-title'
          )
      );
    }
  );

  const filteredExtension = findExtensions(
    filteredName,
    filteredInstructions,
    language
  );

  parsedRawQuestionnaire?.forEach((item, index) => {
    const hasEnoughExtensions = item?.extension?.length > 11;

    if (item?.extension) {
      item?.extension?.forEach((extObj) => {
        if (
          extObj.url ===
            'http://extension.actimi.com/questionnaire-navigation-title' &&
          hasEnoughExtensions &&
          extObj.valueString !==
            filteredExtension?.nameExtension?.[index]?.valueString
        ) {
          extObj.valueString =
            filteredExtension?.nameExtension?.[index]?.valueString;
        }
        if (
          extObj?.url === 'http://extension.actimi.com/exercise-assistant' &&
          hasEnoughExtensions
        ) {
          extObj?.extension?.map((item) => {
            if (
              item.url ===
                'http://extension.actimi.com/exercise-assistant-instructions' &&
              !checkExtensionIsSame(
                item.extension,
                filteredExtension?.instructionExtension?.[index]?.extension
              )
            ) {
              item.extension =
                filteredExtension?.instructionExtension?.[index]?.extension;
            }
          });
        }
      });
    }
  });
  return data;
};

export const checkExtensionIsSame = (extension, filteredExtension) => {
  const first =
    extension?.[0]?.valueCodeableConcept?.coding?.[0]?.display ===
    filteredExtension?.[0]?.valueCodeableConcept?.coding?.[0]?.display;
  const second =
    extension?.[1]?.valueString === filteredExtension?.[1]?.valueString;
  const third =
    extension?.[2]?.valueString === filteredExtension?.[2]?.valueString;
  return first && second && third;
};

export const extensionPathByLanguage = (language: string) => {
  const shortLanguage = language?.slice(0, 2);
  switch (shortLanguage) {
    case 'en':
      return {
        name: 'http://extension.actimi.com/questionnaire-navigation-title',
        instructions:
          'http://extension.actimi.com/exercise-assistant-instructions'
      };
    case 'de':
      return {
        name: 'http://extension.actimi.com/questionnaire-navigation-title',
        instructions:
          'http://extension.actimi.com/exercise-assistant-instructions'
      };
    case 'tr':
      return {
        name: 'http://extension.actimi.com/questionnaire-navigation-title',
        instructions:
          'http://extension.actimi.com/exercise-assistant-instructions'
      };
    default:
      return {
        name: 'http://extension.actimi.com/questionnaire-navigation-title',
        instructions:
          'http://extension.actimi.com/exercise-assistant-instructions'
      };
  }
};

const findExtensions = (name: any, instruction: any, language: string) => {
  const shortLanguage = language?.slice(0, 2);
  if (shortLanguage === 'de') {
    const foundNameExtension = name?.map((item: any) => {
      return item.find(
        (x: Extension) =>
          x.url === 'http://extension.actimi.com/questionnaire-navigation-title'
      );
    });
    const foundInstructionExtension = instruction?.map((item: any) => {
      return item.find(
        (x: Extension) =>
          x.url ===
          'http://extension.actimi.com/exercise-assistant-instructions'
      );
    });

    return {
      nameExtension: foundNameExtension,
      instructionExtension: foundInstructionExtension
    };
  } else {
    return {
      nameExtension: name.map((item: Extension[]) => {
        const foundItem = item?.find(
          (x: Extension) => x?.url?.slice(-2) === shortLanguage
        );
        const fallback = item.find(
          (x: Extension) =>
            x.url ===
            'http://extension.actimi.com/questionnaire-navigation-title'
        );
        return foundItem || fallback;
      }),
      instructionExtension: instruction?.map((item: Extension[]) => {
        const foundItem = item?.find(
          (x: Extension) => x?.url?.slice(-2) === shortLanguage
        );
        const fallback = item.find(
          (x: Extension) =>
            x.url ===
            'http://extension.actimi.com/exercise-assistant-instructions'
        );
        return foundItem || fallback;
      })
    };
  }
};

export enum MARKER_ENUM {
  TIP_MARKER = '!22334455!: ',
  ATTENTION_MARKER = '!3439345!: ',
  INSTRUCTION_MARKER = '!9433385!: '
}
