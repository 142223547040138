/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Grid from './Grid';
import GridContainer from './GridContainer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Header from './Header';
import { Box, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { Transition } from '../../Database/components/ExerciseModal';
import { useSelector } from 'src/store';

export const InvitePatientModal: React.FC<{
  open: boolean;
  isMounted?: boolean;
  handleSubmit: (
    email: string,
    patientName: string,
    patientSurname: string
  ) => void;
  handleClose: () => void;
}> = ({ open, handleClose, handleSubmit, ...props }) => {
  const [patientName, setPatientName] = React.useState<string>();
  const [patientSurname, setPatientSurname] = React.useState<string>();
  const [patientEmail, setPatientEmail] = React.useState<string>();
  const { t } = useTranslation();

  const unMount = () => {
    setPatientName(undefined);
    setPatientSurname(undefined);
    setPatientEmail(undefined);
  };

  const validEmail = (email: string): boolean => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email) return false;
    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleClose();
        unMount();
      }}
      TransitionComponent={Transition}
    >
      <Box>
        <Typography
          id="modal-modal-title"
          variant="h3"
          sx={{ textAlign: 'center', p: 2 }}
          component="h2"
        >
          {t('Invite New Patient')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ p: 1, px: 2, textAlign: 'center' }}
        >
          {t('Email verification')}
        </Typography>
        <Box sx={{ p: 1, px: 4 }}>
          <Box
            sx={{ py: 2, width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            <TextField
              sx={{
                width: '100%',
                mr: 1
              }}
              id="outlined-basic"
              label={t('Patient Name')}
              size="medium"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
              variant="outlined"
            />
            <TextField
              sx={{
                width: '100%'
              }}
              id="outlined-basic"
              label={t('Patient Surname')}
              size="medium"
              value={patientSurname}
              onChange={(e) => setPatientSurname(e.target.value)}
              variant="outlined"
            />
          </Box>
          <Box sx={{ py: 2, width: '100%' }}>
            <TextField
              sx={{
                width: '100%'
              }}
              id="outlined-basic"
              size="medium"
              value={patientEmail}
              onChange={(e) => setPatientEmail(e.target.value)}
              label={t('Patient Email')}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box ml={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClose();
                unMount();
              }}
              style={{
                height: 48,
                marginRight: 12,
                width: '10vw'
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                props.isMounted
                  ? true
                  : validEmail(patientEmail) &&
                    patientName?.length > 0 &&
                    patientSurname?.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                handleSubmit(patientEmail, patientName, patientSurname);
                unMount();
              }}
              sx={{
                '&.Mui-disabled': {
                  borderWidth: 1,
                  borderColor: 'red',
                  color: '#c0c0c0'
                },
                height: 48,
                marginRight: 12,
                color: '#FFF',
                width: '10vw'
              }}
            >
              {props.isMounted ? 'Sending...' : t('Send')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

function Title() {
  const { t }: { t: any } = useTranslation();
  const total = useSelector((state) => state.clinic.totalOfTable);
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Helmet>
        <title>{t('Patients')}</title>
      </Helmet>
      <PageTitleWrapper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '90vw'
          }}
        >
          <Box>
            <Header title={t('Patients')} />
            <Typography variant="h4" color={'#17255680'}>
              {(total as { total: number; type: string })?.type === 'invite'
                ? t('Invited Patient', {
                    total: (total as { total: number; type: string })?.total
                  })
                : t('Active Patient', {
                    total: (total as { total: number; type: string })?.total
                  })}
            </Typography>
          </Box>
        </Box>
      </PageTitleWrapper>
    </Stack>
  );
}

function PatientsTable() {
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  return (
    <>
      <Title />
      <GridContainer>
        <Grid
          page={page}
          pageSize={pageSize}
          pageSizeChange={(pageSize) => setPageSize(pageSize)}
          pageChange={(page) => setPage(page)}
        />
      </GridContainer>
    </>
  );
}

export default PatientsTable;
