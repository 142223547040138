import { TFunction } from 'i18next';
import {
  IFormattedGraphicData,
  IGraphicInformation,
  MultipleGraphDataResponse,
  MultipleGraphicDataType
} from '../models/graphic/IGraphic';
import { MeasurementTypeKey, ObservationCode } from '@actimi/core-migration';

export const checkSingleGraphDataNullity = (
  data?: IGraphicInformation
): boolean => {
  if (!data) {
    return true;
  }
  return data.latestMeasuredValue === null;
};

export const isGraphDataNull = (
  data: MultipleGraphDataResponse | undefined
): boolean => {
  // Check if data exists and all of its latestMeasured values are not nullish
  // If not return true

  if (!data?.length) return true;
  return data.some((d) => d.latestMeasuredValue === null);
};

export const isMultipleGraphDataResponse = (
  response: unknown
): response is MultipleGraphDataResponse => {
  const partial: Partial<MultipleGraphDataResponse> =
    response as Partial<MultipleGraphDataResponse>;
  return partial !== undefined && partial[0] !== undefined;
};

export const isMultipleGraphicDataType = (
  graphicData: unknown
): graphicData is MultipleGraphicDataType => {
  const partial: Partial<MultipleGraphicDataType> =
    graphicData as Partial<MultipleGraphicDataType>;
  return (
    partial !== undefined &&
    partial[0] !== undefined &&
    partial[0][0] !== undefined
  );
};
export const formatValueToResult = (
  value: number,
  typeKey: MeasurementTypeKey,
  t: TFunction
) => {
  if (typeKey === MeasurementTypeKey.mood) {
    switch (value) {
      case 5:
        return t('Great');
      case 4:
        return t('Good');
      case 3:
        return t('Neutral');
      case 2:
        return t('Bad');
      case 1:
        return t('Horrible');
      default:
        return '--';
    }
  } else if (typeKey === MeasurementTypeKey.sleepQuality) {
    switch (value) {
      case 1:
        return t('Insomnia');
      case 2:
        return t('Restless');
      case 3:
        return t('Difficult');
      case 4:
        return t('Good');
      case 5:
        return t('Very Restful');
      default:
        return '--';
    }
  } else if (typeKey === MeasurementTypeKey.muscleSoreness) {
    switch (value) {
      case 5:
        return t('Very Sore');
      case 4:
        return t('Sore');
      case 3:
        return t('Normal');
      case 2:
        return t('Feeling good');
      case 1:
        return t('Feeling great');
      default:
        return '--';
    }
  } else if (typeKey === MeasurementTypeKey.stressLevel) {
    switch (value) {
      case 5:
        return t('Highly Stressed');
      case 4:
        return t('Stressed');
      case 3:
        return t('Normal');
      case 2:
        return t('Relaxed');
      case 1:
        return t('Very Relaxed');
      default:
        return '--';
    }
  } else if (typeKey === MeasurementTypeKey.fatigue) {
    switch (value) {
      case 5:
        return t('Always tired');
      case 4:
        return t('More tired');
      case 3:
        return t('Normal');
      case 2:
        return t('Fresh');
      case 1:
        return t('Very fresh');
      default:
        return '--';
    }
  }
};

export const formatValueToPainLevelTitle = (
  val: number,
  t: TFunction
): string | undefined => {
  if ((val as number) >= 0 && (val as number) <= 1) {
    return t('No Pain');
  }
  if ((val as number) > 1 && (val as number) <= 3) {
    return t('Mild');
  }
  if ((val as number) > 3 && (val as number) <= 5) {
    return t('Moderate');
  }

  if ((val as number) > 5 && (val as number) <= 7) {
    return t('Severe');
  }

  if ((val as number) > 7 && (val as number) <= 9) {
    return t('Very Severe');
  }

  if ((val as number) > 9 && (val as number) <= 10) {
    return t('Worst');
  } else {
    return '--';
  }
};

export const formatValueToAcuteWorkloadTitle = (
  val: number,
  t: TFunction
): { title: string; color: string } | undefined => {
  if ((val as number) >= 0 && (val as number) <= 0.8) {
    return { title: t('Easy'), color: '#1C8ECE' };
  }
  if ((val as number) > 0.8 && (val as number) <= 1.2) {
    return { title: t('Sweet spot'), color: '#43B07C' };
  }
  if ((val as number) > 1.2 && (val as number) <= 1.5) {
    return { title: t('Moderate'), color: '#ED9C00' };
  }
  if ((val as number) > 1.5) {
    return { title: t('Hard'), color: '#FC6262' };
  } else {
    return { title: '--', color: '#43B07C' };
  }
};

export const checkHaveGraphValue = (data): boolean => {
  for (let item of data) {
    if (item.average !== null) {
      return false;
    }
  }
  return true;
};

export const typeKeyToTitle = (type: string, t: TFunction) => {
  switch (type) {
    case MeasurementTypeKey.fatigue:
      return t('Fatigue');
    case MeasurementTypeKey.mood:
      return t('Mood');
    case MeasurementTypeKey.sleepQuality:
      return t('Sleep Quality');
    case MeasurementTypeKey.stressLevel:
      return t('Stress Level');
    case MeasurementTypeKey.muscleSoreness:
      return t('Muscle Soreness');
    case MeasurementTypeKey.pain:
      return t('Pain Level');
    default:
      return t('Fatigue');
  }
};

export const isMultipleFormattedGraphicData = (
  graphicData: unknown
): graphicData is IFormattedGraphicData[][] => {
  const partial: Partial<IFormattedGraphicData[][]> = graphicData as Partial<
    IFormattedGraphicData[][]
  >;
  return partial[0] !== undefined && partial[0][0] !== undefined;
};

export const generateScatterColor = (val: number): string => {
  if ((val as number) >= 0 && (val as number) <= 1) {
    return '#43B07C';
  }
  if ((val as number) > 1 && (val as number) <= 3) {
    return ' #46BA82';
  }
  if ((val as number) > 3 && (val as number) <= 5) {
    return '#F9C74F';
  }

  if ((val as number) > 5 && (val as number) <= 7) {
    return '#F8961E';
  }

  if ((val as number) > 7 && (val as number) <= 9) {
    return '#FF4F01';
  }

  if ((val as number) > 9 && (val as number) <= 10) {
    return '#FD515F';
  }
};

// export const getPainScatterColorOfNewest = (val) => {
//   if ((val as number) >= 0 && (val as number) <= 1) {
//     return 'rgba(174, 220, 197, 1)';
//   }
//   if ((val as number) > 1 && (val as number) <= 3) {
//     return 'rgba(70, 186, 130, 0.5)';
//   }
//   if ((val as number) > 3 && (val as number) <= 5) {
//     return 'rgba(249, 199, 79, 0.5)';
//   }

//   if ((val as number) > 5 && (val as number) <= 7) {
//     return 'rgba(252, 203, 175, 1)';
//   }

//   if ((val as number) > 7 && (val as number) <= 9) {
//     return 'rgba(251, 209, 217, 1)';
//   }

//   if ((val as number) > 9 && (val as number) <= 10) {
//     return 'rgba(253, 81, 95, 0.5)';
//   }
// };

// export const getScatterColorOfNewest = (val) => {
//   switch (val) {
//     case 5:
//       return '#D1EEFF';

//     case 4:
//       return '#CFF7E9';

//     case 3:
//       return '#FFF6C6';

//     case 2:
//       return '#FFF4E3';

//     case 1:
//       return '#FFE5EA';

//     default:
//       return '#FFE5EA';
//   }
// };
export const getScatterColorOfNewest = (val) => {
  return '#8B92AB';
};

export const getScatterStrokeColor = (val) => {
  return '#61CFE4';
};
// export const getScatterStrokeColor = (val) => {
//   switch (val) {
//     case 5:
//       return '#1D9DE6';

//     case 4:
//       return '#46BA82';

//     case 3:
//       return '#FFE872';

//     case 2:
//       return '#FFA552';

//     case 1:
//       return '#FC6262';

//     default:
//       return '#1D9DE6';
//   }
// };

export const orderNoToStrokeWidth = (val: number) => {
  switch (val) {
    case 0:
      return { stroke: 0, width: 6 };
    case 1:
      return { stroke: 5, width: 9 };

    case 2:
      return { stroke: 4, width: 7 };

    case 3:
      return { stroke: 3, width: 6 };

    case 4:
      return { stroke: 2, width: 6 };
    case 5:
      return { stroke: 1, width: 6 };

    default:
      return { stroke: 5, width: 8 };
  }
};

export const getBarChartColor = (val: number): string => {
  if ((val as number) < 0.8) {
    return ' #FFEFB8;';
  }
  if ((val as number) >= 0.8 && (val as number) <= 1.3) {
    return '#46BA82';
  }
  if ((val as number) >= 1.4 && (val as number) <= 1.7) {
    return '#FFCE85';
  }

  if ((val as number) >= 1.8) {
    return '#FF9DAF';
  }
};

export const getBarChartColorArr = (val: any[]) => {
  let colors = [];

  for (let i = 0; i < val?.length; i++) {
    if (val?.[i] && val?.[i]?.acwr === null) colors.push('');
    if ((val?.[i]?.acwr as number) < 0.8) {
      colors.push('#F9C750');
    }
    if (
      (val?.[i]?.acwr as number) >= 0.8 &&
      (val?.[i]?.acwr as number) <= 1.3
    ) {
      colors.push('#46BA82');
    }
    if (
      (val?.[i]?.acwr as number) >= 1.4 &&
      (val?.[i]?.acwr as number) <= 1.7
    ) {
      colors.push('#F8961D');
    }

    if ((val?.[i]?.acwr as number) >= 1.8) {
      colors.push('#FD515F');
    } else {
      colors.push('#FD515F');
    }
  }

  return colors.slice(0, 7);
};
