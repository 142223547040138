/* eslint-disable jsx-a11y/alt-text */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDataComponent: React.FC<{
  img: string;
  label: string;
  another?: boolean;
}> = ({ label, img, another }) => {
  const { t }: { t: any } = useTranslation();
  const imgSize = {
    width: 250,
    height: 250,
    alignSelf: 'center'
  };
  const titlesOfOverview = another
    ? [
        t('Name'),
        t('Health Problem'),
        t('Birth Date'),
        t('Enrollment Date'),
        t('End Date'),
        t('Actions')
      ]
    : [
        t('Name'),
        t('Health Problem'),
        t('Pain Level'),
        t('Workload Ratio'),
        t('Activity Goal'),
        t('Last Update')
      ];
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#F5F8FB'
            }}
          >
            {titlesOfOverview.map((item, index) => (
              <TableCell
                key={index}
                sx={{
                  marginRight: 4
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>

      <Box
        sx={{
          height: 500,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ alignSelf: 'center', ml: 12 }}>
          <img src={img} style={imgSize} />
          <Typography
            mt={2}
            ml={2}
            width={'60%'}
            variant="subtitle1"
            textAlign={'center'}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const NoData = React.memo(NoDataComponent);
