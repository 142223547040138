import React, { forwardRef } from "react";
import { Tabs, Tab } from "@mui/material";
import { TabsActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface RoundedTabsProps {
  className?: string;
  disabled?: boolean;
  orientation?: "horizontal" | "vertical";
  onChange?: (event: React.SyntheticEvent, value: any) => void;
  sx?: Object;
  variant?: "fullWidth" | "scrollable" | "standard";
  action?: React.Ref<TabsActions>;
  centered?: boolean;
  value: number | string;
  tabData?: Array<{ label: string; value: string }>;
}

const RoundedTabs = forwardRef(
  (
    { tabData, orientation, disabled, value, onChange }: RoundedTabsProps,
    action: React.Ref<TabsActions>
  ) => {
    const { t } = useTranslation();

    return (
      <Tabs
        action={action}
        orientation={orientation ?? "horizontal"}
        textColor="inherit"
        value={value}
        onChange={onChange}
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          height: "100%",
        }}
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#26BDD8",
            borderRadius: 40,
            border: "none",
            boxShadow: "none",
            fontWeight: 600,
            fontFamily: "Poppins",
            fontSize: 14,
            gap: 10,
            padding: "7px, 23px, 7px, 23px",
          },
          sx: {
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          },
        }}
      >
        {tabData.map((item) => (
          <Tab
            sx={{
              color: "rgba(23, 37, 86, 0.5)",
              opacity: 0.9,
              fontWeight: 600,

              "&.Mui-selected, &.Mui-selected:hover": {
                color: {
                  xs: "#26BDD8",
                  sm: "#26BDD8",
                  md: "#FFFFFF",
                  lg: "#FFFFFF",
                  xl: "#FFFFFF",
                },
              },
            }}
            disabled={disabled ?? false}
            key={item.value}
            label={t(`${item.label}`)}
            value={item.value}
          />
        ))}
      </Tabs>
    );
  }
);

export default RoundedTabs;
