import { Task, TimeSpan } from '@actimi/core-migration';
import { TFunction } from 'i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/tr';

export const formatDateObject = (date: string | Date): string => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatNotificationScreenHeaderDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY, dddd');
  }
  return '';
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}
export const convertSecondTo2Digigts = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const convertSecondToMinuteWithSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds =
    remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  return `${minutes}:${formattedSeconds}`;
};

export const daysInMonth = (): number => {
  return moment().daysInMonth();
};

export const formatMeasurementCardDate = (date: string | undefined): string => {
  if (date) {
    return moment(date).format('dddd, DD MMMM YYYY | HH:mm');
  }
  return '';
};

export const formatThryveDate = (date: string | undefined): string => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export const formatSavedObservationDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('DD MM YYYY, HH:mm');
  }
  return '';
};

export const formatDetailedGraphicCardDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('MMMM Do, h:mm a');
  }
  return '';
};

export const formatTrackCardDate = (date: string | null): string => {
  if (date != null) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
  return '';
};
export const formatTooltipDateTime = (date: string | null): string => {
  if (date != null) {
    return `- ${moment(date).format('h:mm A')}`;
  }
  return '';
};

export const formatNotificationScreenItemDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
  return '';
};

export const formatCareTeamDate = (date: string): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY ');
  }
  return '';
};

export const formatExerciseFeedbackDate = (date: string): string => {
  return moment(date).format('DD/MM/YYYY ');
};

export const formatLastMeasurementDate = (
  date: string | number
): string | Date => {
  if (typeof date === 'string' && date.includes('GMT')) {
    return moment(new Date(date)).calendar();
  }
  if (date) {
    return moment(date).calendar();
  }
  return '';
};

export const formatLastMeasurementDateWithoutAMPM = (
  date: string | number
): string | Date => {
  if (typeof date === 'string' && date.includes('GMT')) {
    if (moment(new Date(date)).calendar().split(' ').length > 4) {
      return `${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(0, 1)} ${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(1, 2)} ${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(2, 3)} ${moment(new Date(date)).format('HH:mm')}`;
    } else {
      return `${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(0, 1)} ${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(1, 2)} ${moment(new Date(date)).format('HH:mm')}`;
    }
  }
  if (date) {
    return moment(date).format('HH:mm');
  }
  return '';
};

export const getTimeSpan = (timeSpan: TimeSpan): string => {
  switch (timeSpan) {
    case TimeSpan.WEEK: {
      const startWeekDay = moment().startOf('week').format('DD');
      const endWeekDay = moment().endOf('week').format('Do MMM');
      return `${startWeekDay} - ${endWeekDay}`;
    }
    case TimeSpan.MONTH: {
      const startMonthDay = moment().startOf('month').format('Do MMM');
      const endMonthDay = moment().endOf('month').format('Do MMM');
      return `${startMonthDay} - ${endMonthDay}`;
    }
    case TimeSpan.YEAR: {
      const startYearDay = moment().startOf('year').format('Do MMM YYYY');
      const endYearDay = moment().endOf('year').format('Do MMM YYYY');
      return `${startYearDay} - ${endYearDay}`;
    }
    default: {
      throw new Error('Unknown TimeSpan');
    }
  }
};

export const renderDate = (timeSpan: TimeSpan): string => {
  const today = moment();
  let dateTime;
  switch (timeSpan) {
    case TimeSpan.TODAY:
      dateTime = moment().format('dddd, D MMMM');
      return dateTime;
    case TimeSpan.MONTH:
      dateTime = today.format('MMMM') + ', ' + today.format('YYYY');
      return dateTime;
    case TimeSpan.YEAR:
      dateTime = today.format('YYYY');
      return dateTime;
    case TimeSpan.WEEK:
      dateTime =
        today.startOf('week').format('D') +
        ' - ' +
        today.endOf('week').format('DD MMMM YYYY');
      return dateTime;
    default:
      dateTime =
        moment().startOf('week').format('DD') +
        ' - ' +
        today.endOf('week').format('DD MMMM, YYYY');
      return dateTime;
  }
};

export const renderTimeSpanDateTime = (
  timeSpan: TimeSpan,
  dateTime: string,
  language: string
): string => {
  const slicedLanguage = language.slice(0, 2);
  const mountDays = moment(dateTime).locale(slicedLanguage).daysInMonth();

  const momentInstance = moment(dateTime).locale(slicedLanguage);
  switch (timeSpan) {
    case TimeSpan.TODAY:
      return momentInstance.format('dddd, D MMMM');
    case TimeSpan.MONTH:
      return (
        momentInstance.format('MMM') +
        ' ' +
        1 +
        '–' +
        momentInstance.format('MMM') +
        ' ' +
        mountDays +
        ', ' +
        momentInstance.format('YYYY')
      );
    case TimeSpan.WEEK:
      return (
        momentInstance.format('D MMM') +
        '–' +
        momentInstance
          .utcOffset(0)
          .add(6, 'days')
          .endOf('day')
          .format('D MMM YYYY')
      );
    case TimeSpan.YEAR:
      return (
        momentInstance.format('MMM YYYY') +
        '–' +
        momentInstance.add(11, 'months').format('MMM YYYY')
      );
    // return moment(dateTime).format('YYYY');
  }
};

export const renderMinMaxDateForWeekly = (
  minDate: string,
  maxDate: string
): string => {
  return (
    moment(minDate).format('DD') +
    ' - ' +
    moment(maxDate).format('DD MMMM YYYY')
  );
};

export function isTwoDaysAgo(dateString) {
  const today = new Date();
  const givenDate = new Date(dateString);
  const twoDaysAgo = new Date(today.setDate(today.getDate() - 2));
  return givenDate <= twoDaysAgo;
}

export const taskDateFormatter = (date: string): string => {
  // if (isToday(date as string)) return `Today, ${moment(date).format('HH:mm')}`;
  // //@ts-ignore
  // if (isYesterday(date)) return 'Yesterday';
  return `${
    moment(date).format('ddd DD MMM').substring(0, 3) +
    ',' +
    moment(date)
      .format('ddd DD MMM')
      .substring(3, moment(date).format('ddd DD MMM').length)
  }`;
};

export const isToday = (_date: string): boolean => {
  return new Date().toDateString() === new Date(_date).toDateString();
};

export const getUserAge = (userBirthDate: string): number => {
  return moment().diff(userBirthDate, 'years');
};

export const loadZeroToDate = (dates: string[]): string[] => {
  return dates.map((y) => {
    if (y.length === 1) {
      return '0' + y;
    }
    return y;
  });
};

export const getFirstMondayOfMonth = (): string => {
  let date = moment()
    .set('year', new Date().getFullYear())
    .set('month', new Date().getMonth())
    .set('date', new Date().getDate())
    .isoWeekday(8);
  if (date.date() > 7) {
    date = date.isoWeekday(-6);
  }
  return date.toISOString();
};
export const isYesterday = (_date: number | string): boolean => {
  return moment(_date).isSame(moment().subtract(1, 'day'), 'day');
};

export const formatChatListItemTime = (
  dateTime: number | string,
  t?: TFunction
): string => {
  if (isToday(dateTime as string)) return moment(dateTime).format('HH:mm');
  //@ts-ignore
  if (isYesterday(dateTime)) return 'Yesterday';
  return moment(dateTime).format('D/M/YY');
};
export const formatRecentActivtyDateTime = (
  dateTime: number | string,
  t?: TFunction
): string => {
  if (isToday(dateTime as string))
    return `Today, ${moment(dateTime).format('HH:mm')}`;
  //@ts-ignore
  if (isYesterday(dateTime))
    return `Yesterday, ${moment(dateTime).format('HH:mm')}`;
  return `${dateTime?.toString()?.slice(8, 10)} ${numberToMonth(
    moment(dateTime).month() + 1,
    t
  )}, ${moment(dateTime).year()}`;
};

export const formatFullDateAndDay = (date: string | undefined): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY, dddd');
  }
  return '';
};
export const numberToMonth = (val: number, t: TFunction): string => {
  switch (val) {
    case 1:
      return t('January');
    case 2:
      return t('February');
    case 3:
      return t('March');
    case 4:
      return t('April');
    case 5:
      return t('May');
    case 6:
      return t('June');
    case 7:
      return t('July');
    case 8:
      return t('August');
    case 9:
      return t('September');
    case 10:
      return t('October');
    case 11:
      return t('November');
    case 12:
      return t('December');
    default:
      return t('January');
  }
};

export function romanize(num) {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX'
    ],
    roman = '',
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

export const groupDataByDate = (data: Task[]) => {
  const groups = data?.reduce((groups, tasks) => {
    const date = tasks?.executionPeriod?.end?.split('T')?.[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(tasks);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays =
    groups &&
    Object?.keys(groups)?.map((date) => {
      return {
        date,
        tasks: groups[date]
      };
    });

  return groupArrays.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};
