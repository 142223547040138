/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FC, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack
} from '@material-ui/core';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useDispatch, useSelector } from 'src/store';
import { makeStyles } from '@mui/styles';
import * as uuid from 'uuid';
import SuspenseLoader from 'src/components/SuspenseLoader';
import TabsContainer from 'src/components/TabsContainer';
import ExerciseOverviewTab from './ExerciseOverviewTab';
import InstructionsTab from './InstructionsTab';
import ParametersTab from './ParametersTab';
import { Form, Formik } from 'formik';
import {
  ErrorMessage,
  exerciseValidationSchema,
  findLaguagesByCurrent,
  IExerciseFromValues,
  parseResponseTranslation,
  parseToFormValues,
  prepareTranslateInput,
  questionnaireFactory,
  SuccessMessage,
  uploadFile
} from '../utils';
import { toast } from 'react-toastify';
import slice, {
  createExercise,
  getExerciseQuestionnaire,
  updateExercise
} from 'src/slices/exercise';
import axiosAidboxInt from 'src/utils/axios';
import { useTranslation } from 'react-i18next';
import AlertsTab from './Alerts';
import {
  QuestionnaireService,
  Questionnaire,
  QuestionnaireItem
} from '@actimi/core-migration';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import { batch } from 'react-redux';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ExerciseModalProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly data: any;
  readonly exerciseId: string | undefined;
  readonly isDisabledText: boolean;
  readonly isEdit: boolean;
  readonly selectedExercise: QuestionnaireItem;
}

const ExerciseModal: FC<ExerciseModalProps> = ({
  open,
  handleClose,
  data,
  isDisabledText,
  selectedExercise,
  ...props
}) => {
  const auth = useAuth();
  const practitioner = useSelector((state) => state.clinic.practitionerInfo);
  const classes = useStyles();
  const videoFile = useSelector((state) => state.exercise.videoFile);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('Preparing...');
  const isAddExercise = !selectedExercise || props.isEdit;
  const thumbnail = useSelector((state) => state.exercise.snapShotVideo);
  const { t }: { t: any } = useTranslation();
  const {
    tipInputs,
    attentionInputs,
    instructionsInputs,
    exerciseBase,
    videoURI
  } = useSelector((state) => state.exercise);
  const currentLanguage = window.localStorage.getItem('i18nextLng');

  const handleTranslateContent = async (values, currentLanguage: string) => {
    try {
      const findLanguages = findLaguagesByCurrent(currentLanguage);
      const prepareInputs = findLanguages.map((lang) => {
        return prepareTranslateInput(
          values,
          lang.toUpperCase(),
          currentLanguage.toUpperCase()
        );
      });

      const requests = prepareInputs.map((item) => {
        return axiosAidboxInt.post(`translate`, item);
      });
      const responses = await Promise.all(requests);
      const responseData = responses.map((response) => response.data);
      const parsedResponses = responseData.map((item, index) => {
        return parseResponseTranslation(
          item?.translations,
          findLanguages?.[index]
        );
      });
      return parsedResponses;
    } catch (error) {
      ErrorMessage(t('translation-error'), 'top-center');
    }
  };

  React.useEffect(() => {
    if (props.exerciseId) {
      selectedExercise.extension.map((i) => {
        if (i.url === 'http://extension.actimi.com/questionnaire-video') {
          dispatch(slice.actions.setVideoURI(i.valueUrl));
        }
        if (
          i.url ===
          'http://extension.actimi.com/questionnaire-video-video-thumbnail'
        ) {
          dispatch(slice.actions.setVideoThumb(i.valueUrl));
        }
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [selectedExercise]);

  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(slice.actions.resetInstructions());
        dispatch(slice.actions.setVideoFile(null));
        dispatch(slice.actions.setVideoURI(null));
      });
    };
  }, []);

  const tabs = React.useMemo(
    () => [
      { value: 'exercise-overview', label: t('Exercise Overview') },
      { value: 'instructions', label: t('Instructions') },
      { value: 'alerts', label: t('Attention&Tips') },
      { value: 'parameters', label: t('Parameters') }
    ],
    []
  );
  const getForcedIndex = (errors?: any, touched?: any) => {
    if ('exercise-overview' in errors && 'exercise-overview' in touched)
      return 0;
    if ('instructions' in errors && 'instructions' in touched) return 1;
    if ('alerts' in errors && 'alerts' in touched) return 2;
    if ('parameters' in errors && 'parameters' in touched) return 3;
  };

  const initialFormValues = React.useMemo(() => {
    return selectedExercise
      ? parseToFormValues(selectedExercise, t)
      : parseToFormValues();
  }, [selectedExercise]);

  React.useEffect(() => {
    const instructions = [];
    const attention = [];
    const tip = [];
    if (props.isEdit) {
      selectedExercise.extension
        ?.flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (
                value.url ===
                'http://extension.actimi.com/exercise-assistant-instructions'
              ) {
                value.extension[0].valueCodeableConcept.coding.flatMap(
                  (item) => {
                    instructions.push({ service: item.display });
                  }
                );
                value.extension[1]?.valueString.split('-').map((item) => {
                  attention.push({ attention: item });
                });
                value.extension[2]?.valueString.split('-').map((item) => {
                  tip.push({ tip: item });
                });
              }
            });
          }
        })
        .filter((x) => x !== undefined);

      batch(() => {
        dispatch(slice.actions.setInputsOfInstructions(instructions));
        dispatch(slice.actions.setInputsOfAttention(attention));
        dispatch(slice.actions.setInputsOfTip(tip));
      });
    }
  }, [selectedExercise]);

  const validationSchema = React.useMemo(() => exerciseValidationSchema(), []);

  const ffmpeg = createFFmpeg({
    log: true
  });

  async function compressVideo(videoFile: File) {
    await ffmpeg.load();
    setMessage('Converting video...');
    ffmpeg.FS('writeFile', videoFile?.name, await fetchFile(videoFile));

    await ffmpeg.run(
      '-i',
      videoFile?.name,
      '-c:v',
      'libx264',
      'newExercise.mp4'
    );
    setMessage('Complete converting proces..');
    let data = ffmpeg.FS('readFile', 'newExercise.mp4');
    // let blob = new Blob([data], {
    //   type: 'video/mp4'
    // });

    setMessage('Saving exercise...');
    // let newFile = new File([blob], 'exercise.mp4');

    return data;
  }

  async function onSubmit(values: IExerciseFromValues) {
    if (props.isEdit) {
      try {
        const translationResponse = await handleTranslateContent(
          {
            instructionsInputs,
            attentionInputs,
            tipInputs,
            exerciseName: values.exercise.exerciseName
          },
          currentLanguage
        );
        const thumbnail = selectedExercise?.extension
          ?.flatMap((extension) => {
            if (
              extension.url ===
              'http://extension.actimi.com/questionnaire-video-video-thumbnail'
            ) {
              return extension.valueUrl;
            }
          })
          .filter((x) => x !== undefined);
        const body: Questionnaire = questionnaireFactory({
          values,
          videoURI,
          thumbnail: String(thumbnail),
          practitionerId: practitioner?.id,
          instructionsInputs,
          attentionInputs,
          tipInputs,
          questionnaireId: selectedExercise.id,
          exerciseBase,
          language: currentLanguage,
          translationResponse
        });

        body['id'] = props.exerciseId;

        dispatch(
          updateExercise(body, practitioner.id, t, () =>
            dispatch(
              getExerciseQuestionnaire(
                'mifysio-exercise',
                currentLanguage,
                practitioner.id
              )
            )
          )
        );
        dispatch(slice.actions.setVideoFile(undefined));
        dispatch(slice.actions.setVideoThumb(undefined));
        handleClose();
      } catch (err) {
        toast(`You can't edit public exercise`, {
          autoClose: 2000,
          type: 'error',
          position: 'top-center'
        });
      }
    } else {
      if (videoFile) {
        try {
          let newFile = await compressVideo(videoFile);
          const [header, b64Data] = thumbnail.split(',');
          const imageBuffer = Buffer.from(b64Data, 'base64');
          const videoBuffer = newFile;

          const questionnaireId = uuid.v4();
          const body = {
            bucketId: 'mifysio-app',
            accountId: 'mifysio-app',
            organizationId: auth.user.clinic.id,
            resourceId: questionnaireId,
            id: practitioner.id,
            resourceType: 'Questionnaire',
            fileName: values.exercise.exerciseName
              .replaceAll(' ', '-')
              .toLowerCase()
          };

          try {
            const [responseVideo, translationResponse] = await Promise.all([
              uploadFile(body),
              handleTranslateContent(
                {
                  instructionsInputs,
                  attentionInputs,
                  tipInputs,
                  exerciseName: values.exercise.exerciseName
                },
                currentLanguage
              )
            ]);
            const URLS = {
              image: responseVideo.thumbnail.url,
              video: responseVideo.video.url
            };

            await Promise.all([
              axios.put(URLS.image, imageBuffer.buffer, {
                headers: {
                  'Content-type': 'video/mp4'
                }
              }),
              axios.put(URLS.video, videoBuffer.buffer, {
                headers: {
                  'Content-type': 'video/mp4'
                }
              })
            ]);

            data = null;
            newFile = null;

            const questionnaireBody: Questionnaire = questionnaireFactory({
              values,
              videoURI: responseVideo.video.url.split('?')[0],
              thumbnail: responseVideo.thumbnail.url.split('?')[0],
              practitionerId: practitioner?.id,
              instructionsInputs,
              attentionInputs,
              tipInputs,
              questionnaireId,
              exerciseBase,
              language: currentLanguage,
              translationResponse
            });

            setMessage('Completing...');

            dispatch(
              createExercise(questionnaireBody, () =>
                dispatch(
                  getExerciseQuestionnaire(
                    'mifysio-exercise',
                    currentLanguage,
                    practitioner.id
                  )
                )
              )
            );
            dispatch(slice.actions.setVideoFile(undefined));
            handleClose();

            SuccessMessage(t('Exercise created succesfully.'), 'top-center');
          } catch (error) {
            toast(`${JSON.stringify(error)}`, {
              autoClose: 2000,
              type: 'error',
              position: 'top-center'
            });
          }
        } catch (error) {
          toast(t('Error While Creating Exercise Process'), {
            autoClose: 2000,
            type: 'error',
            position: 'top-center'
          });
          handleClose();
        }
      } else {
        toast(t('Please add exercise video'), {
          autoClose: 2000,
          type: 'error',
          position: 'top-center'
        });
      }
    }
  }

  const exerciseTitle =
    !isAddExercise &&
    data
      ?.filter((id) => id?.id === props?.exerciseId)[0]
      ?.item?.[0]?.extension?.map((title) => {
        if (
          title?.url ===
          'http://extension.actimi.com/questionnaire-navigation-title'
        ) {
          return title.valueString;
        }
      })
      .filter((i) => i !== undefined)[0];
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ py: 4, px: 6 }}>
          <Typography
            sx={{ ml: 2, textAlign: 'flex-start' }}
            variant="h2"
            color="MenuText"
          >
            {isAddExercise ? 'Add New Exercise' : exerciseTitle}
          </Typography>
        </Box>
        {isLoading ? (
          <SuspenseLoader />
        ) : (
          <Formik<IExerciseFromValues>
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validationSchema={validationSchema}
          >
            {({ errors, touched, isSubmitting }) => (
              <React.Fragment>
                <Form>
                  <Box>
                    <TabsContainer
                      tabs={tabs}
                      forcedIndex={getForcedIndex(errors, touched)}
                      panelProps={{ className: classes.tabsPanel }}
                    >
                      {(tabValue: string) => {
                        switch (tabValue) {
                          case 'exercise-overview':
                            return (
                              <ExerciseOverviewTab
                                isDisabledText={isDisabledText}
                                isEdit={props.isEdit}
                              />
                            );
                          case 'instructions':
                            return (
                              <InstructionsTab
                                isDisabledText={isDisabledText}
                                isEdit={props.isEdit}
                                questionnaireData={selectedExercise}
                              />
                            );
                          case 'alerts':
                            return (
                              <AlertsTab
                                isEdit={props.isEdit}
                                isDisabledText={isDisabledText}
                                questionnaireData={selectedExercise}
                              />
                            );
                          case 'parameters':
                            return (
                              <ParametersTab
                                isEdit={props.isEdit}
                                isDisabledText={isDisabledText}
                                parameters={initialFormValues.parameters}
                              />
                            );

                          default:
                            return;
                        }
                      }}
                    </TabsContainer>
                  </Box>
                  <Divider />
                  {(!selectedExercise || props.isEdit) && (
                    <FormActions
                      isEdit={props.isEdit}
                      handleClose={handleClose}
                    />
                  )}
                </Form>
                <BlockUI message={message} open={isSubmitting} />
              </React.Fragment>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
};
const BlockUI = ({ open, message }) => (
  <Backdrop open={open} sx={{ color: '#fff' }}>
    <CircularProgress color="inherit" />
    <Typography
      sx={{
        mx: 3,
        fontSize: 18,
        fontWeight: '500'
      }}
    >
      {message}
    </Typography>
  </Backdrop>
);

const FormActions = ({ handleClose, isEdit }) => {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();

  return (
    <Stack
      borderTop={'1px solid rgba(23, 37, 86, 0.1)'}
      borderBottom={'1px solid rgba(23, 37, 86, 0.1)'}
      direction="row"
      spacing={2}
      sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}
    >
      <Button sx={{ width: 110 }} variant="outlined" onClick={handleClose}>
        {t('Cancel')}
      </Button>
      <Button
        sx={{
          width: 110,
          color: '#ffffff',
          backgroundColor: 'primary'
        }}
        color="primary"
        variant="contained"
        type="submit"
      >
        {isEdit ? t('Edit') : t('Save')}
      </Button>
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  toolBar: {
    justifyContent: 'center',
    flex: 1
  },
  titleResult: {
    color: '#0075E1'
  },
  button: {
    width: 130
  },
  contentBody: {
    flex: 1,
    margin: 8,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row'
  },
  element: {
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  questionElement: {
    display: 'flex',
    flexDirection: 'row'
  },
  answerText: {
    color: '#FD515F'
  },
  input: {
    width: '100%'
  },
  tabsPanel: {
    minHeight: '27rem',
    mt: '1rem',
    padding: '2rem 3rem'
  },
  textItem: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  },
  selectInputElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectInputAvatar: {
    width: 28,
    height: 28,
    borderRadius: 12,
    marginRight: 6
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  programButton: {
    width: '70%'
  }
}));

export default ExerciseModal;
