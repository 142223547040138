import { TFunction } from 'i18next';
export enum ExercisePlan {
  PM = 'Pain Management',
  MODL = 'Movement of Daily Life'
}

export const getCategories = (t: any) => {
  return {
    bodyPart: [
      { value: 'Lower Body', label: t('Lower Body') },
      { value: 'Upper Body', label: t('Upper Body') },
      { value: 'Spine', label: t('Spine') },
      { value: 'Nervous System', label: t('Nervous System') }
    ],
    movementCategory: [
      { value: 'Mobilisation', label: t('Mobilisation') },
      { value: 'Activation', label: t('Activation') },
      { value: 'Integration', label: t('Integration') },
      { value: 'Isometrics', label: t('Isometrics') }
    ],
    library: [
      { value: 'Public', label: t('Public') },
      { value: 'Private', label: t('Private') }
    ],
    level: [
      { value: 'Beginner', label: t('Beginner') },
      { value: 'Intermediate', label: t('Intermediate') },
      { value: 'Advanced', label: t('Advanced') },
      { value: 'Expert', label: t('Expert') }
    ],
    movementPattern: [
      { value: 'hold', label: t('Hold') },
      { value: 'up', label: t('Up') },
      { value: 'down', label: t('Down') }
    ]
  };
};

export const redirectFieldName = (name, value, t) => {
  switch (name) {
    case 'exercise.bodyPart':
      return translateBodyPartValues(value, t);
    case 'exercise.movementCategory':
      return translateMovementCategoryValues(value, t);

    case 'parameters.equipment':
      return convertToolNameWithLocalization(value, t);

    default:
      return value;
  }
};

export const translateMovementCategoryValues = (
  value: string,
  t: TFunction
) => {
  switch (value) {
    case 'Mobilisation':
      return t('Mobilisation');
    case 'Activation':
      return t('Activation');
    case 'Integration':
      return t('Integration');

    case 'Isometrics':
      return t('Isometrics');
  }
};
export const translateBodyPartValues = (value: string, t: TFunction) => {
  switch (value) {
    case 'Lower Body':
      return t('Lower Body');
    case 'Upper Body':
      return t('Upper Body');
    case 'Spine':
      return t('Spine');

    case 'Nervous System':
      return t('Nervous System');
  }
};
export const translateLevelValues = (value: string, t: TFunction) => {
  switch (value) {
    case 'Beginner':
      return t('Beginner');
    case 'Intermediate':
      return t('Intermediate');
    case 'Advanced':
      return t('Advanced');
    case 'Expert':
      return t('Expert');
  }
};

export const convertTranslatedValue = (value: string, t: TFunction) => {
  return value;
};

export const getEquipments = (t: TFunction) => {
  return [
    { value: 'No Equipment', label: t('no-equipment') },
    { value: 'Dumbbell', label: t('dumbbell') },
    { value: 'Cable Pull', label: t('cable_pull') },
    {
      value: 'Elastic Resistance Bands',
      label: t('elastic_resistance_bands')
    },
    { value: 'Kettlebell', label: t('kettlebell') },
    { value: 'Barbell', label: t('barbell') },
    { value: 'Medicine Ball', label: t('medicine_ball') },
    { value: 'Weight Sled', label: t('weight_sled') },
    { value: 'Strength Machines', label: t('strength_machines') },
    { value: 'Ankle/Wrist Weights', label: t('ankle_wrist_weights') },
    {
      value: 'Elastic Resistance Tubes',
      label: t('elastic_resistance_tubes')
    },
    { value: 'Jump Box', label: t('jump_box') },
    { value: 'Jump Rope', label: t('jump_rope') },
    { value: 'Trampoline', label: t('trampoline') },
    { value: 'Foam Roller', label: t('foam_roller') },
    { value: 'Agillity Ladder', label: t('agility_ladder') },
    { value: 'Cross Trainer', label: t('cross_trainer') },
    { value: 'Treadmill', label: t('treadmill') },
    { value: 'Indoor Bike', label: t('indoor_bike') },
    { value: 'Suspension Trainer', label: t('suspension_trainer') },
    { value: 'Gymnastic Rings', label: t('gymnastic_rings') }
  ];
};

export const convertToolNameWithLocalization = (
  tool: string,
  t: TFunction
): string => {
  console.log(tool, 'Tool');

  switch (tool) {
    case 'Dumbbell':
      return t('dumbbell');
    case 'Cable Pull':
      return t('cable_pull');
    case 'Elastic Resistance Bands':
      return t('elastic_resistance_bands');
    case 'Kettlebell':
      return t('kettlebell');
    case 'Barbell':
      return t('barbell');
    case 'Medicine Ball':
      return t('medicine_ball');
    case 'Weight Sled':
      return t('weight_sled');
    case 'Strenght Machines':
      return t('strength_machines');
    case 'Ankle/Wrist Weights':
      return t('ankle_wrist_weights');
    case 'Elastic Resistance Tubes':
      return t('elastic_resistance_tubes');
    case 'Jump Box':
      return t('jump_box');
    case 'Jump Rope':
      return t('jump_rope');
    case 'Trampoline':
      return t('trampoline');
    case 'Foam Ball or similar (e.g. Tennis /Golf Ball, Duo Ball)':
      return t('foam_ball');
    case 'Foam Roller':
      return t('foam_roller');
    case 'Agillity Ladder':
      return t('agility_ladder');
    case 'Cross Trainer':
      return t('cross_trainer');
    case 'Treadmill':
      return t('treadmill');
    case 'Indoor Bike':
      return t('indoor_bike');
    case 'Suspension Trainer (e.g. TRX)':
      return t('suspension_trainer');
    case 'Gymnastic Rings':
      return t('gymnastic_rings');
    default:
      return t('no-equipment');
  }
};

export enum TypeUrlName {
  Library = 'http://extension.actimi.com/questionnaire-exercise-database-type',
  Level = 'http://extension.actimi.com/questionnaire-exercise-level',
  Body = 'http://extension.actimi.com/questionnaire-exercise-body-part',
  Movement = 'http://extension.actimi.com/questionnaire-exercise-movement-category'
}

export const convertIntensityToNumericValue = (intensity: string) => {
  switch (intensity) {
    case 'beginner':
      return 1;
    case 'Beginner':
      return 1;

    case 'intermediate':
      return 2;
    case 'Intermediate':
      return 2;

    case 'advanced':
      return 3;

    case 'expert':
      return 4;
  }
};

export const convertIntensityNumbericToString = (sumOfIntensity) => {
  if (sumOfIntensity >= 1 && sumOfIntensity < 2) {
    return 'Beginner';
  }
  if (sumOfIntensity >= 2 && sumOfIntensity < 3) {
    return 'Intermediate';
  }
  if (sumOfIntensity >= 3 && sumOfIntensity < 4) {
    return 'Advanced';
  }
  if (sumOfIntensity >= 4) {
    return 'Expert';
  }
};
