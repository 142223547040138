/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/models/user';
import axios from 'src/utils/axios';
import { PackRule, packRules, unpackRules } from '@casl/ability/extra';
import {
  AbilityContext,
  createRulesWithRoleAbility
} from '../services/casl/AbilityContext';
import { DoctorAuthService, IClinic } from '@actimi/core-migration';
import AxiosJwt from '../utils/axios';
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'src/content/dashboards/Database/utils';
import { useTranslation } from 'react-i18next';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  rules: PackRule<any>[] | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    email: string,
    password: string,
    name: string,
    surname: string,
    phoneNumber: string,
    practiceName: string,
    practicePhoneNumber: string,
    practiceStreetAddress: string,
    practiceCity: string,
    practiceZipCode: number | string,
    members?: string[],
    avatar?: string
  ) => Promise<void>;
  registerClinic: (jwt: string, user: User) => void;
  setTempSession: (information: ITempInformation) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    rules: PackRule<any>[] | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type SetTempSectionAction = {
  type: 'SET_TEMP_SESSION';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetTempSectionAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  rules: null
};

const setSession = (
  userId: string,
  user: User,
  rules: PackRule<any>[],
  token?: string
): void => {
  if (user) {
    localStorage.setItem('userId', userId);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('rules', JSON.stringify(rules));
    localStorage.setItem('accessToken', token);
    AxiosJwt.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('rules');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, rules } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      rules
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SET_TEMP_SESSION: (
    state: AuthState,
    action: SetTempSectionAction
  ): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setTempSession: () => Promise.resolve(),
  registerClinic: () => Promise.resolve()
});

export interface ITempInformation {
  readonly patient: string;
  readonly jwt: string;
  readonly patientName: string;
  readonly careteamId: string;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { t } = useTranslation();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = window.localStorage.getItem('user');
        const userId = window.localStorage.getItem('userId');
        const rules = window.localStorage.getItem('rules');

        if (user && rules) {
          const parsedUser = JSON.parse(user);
          const parsedRules = JSON.parse(rules);
          const token = window.localStorage.getItem('accessToken');
          AbilityContext.ability.update(unpackRules(parsedRules));
          setSession(userId, parsedUser, parsedRules, token);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                ...parsedUser,
                name: parsedUser.name
              },
              rules: parsedRules
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              rules: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            rules: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const doctorAuthService = new DoctorAuthService();

    try {
      const response = await doctorAuthService.login(username, password);

      const rules = createRulesWithRoleAbility(response.role);
      AbilityContext.ability.update(rules);
      const packedRules = packRules(rules);

      if (response.practitioner.id) {
        setSession(
          response.practitioner.id,
          {
            resourceType: 'Practitioner',
            id: response.practitioner.id,
            name: response.practitioner.name,
            email: response.practitioner.email,
            avatar: response.practitioner.avatar,
            clinic: response.practitioner.clinic as IClinic,
            role: ['doctor']
          },
          packedRules,
          response.access_token
        );
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              resourceType: 'Practitioner',
              id: response.userId,
              name: response.practitioner.name,
              email: response.practitioner.email,
              avatar: response.practitioner.avatar,
              clinic: response.practitioner.clinic as IClinic,
              role: ['doctor']
            }
          }
        });
      }
    } catch (error) {
      if (error.error_description === 'Wrong credentials') {
        ErrorMessage(t('wrong-credentials'), 'top-center');
      }
    }
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const logout = async (): Promise<void> => {
    axios.delete('/Session');
    setSession(null, null, null, null);
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('userId');
    dispatch({ type: 'LOGOUT' });
    refreshPage();
  };

  const register = async (
    email: string,
    password: string,
    name: string,
    surname: string,
    phoneNumber: string,
    practiceName: string,
    practicePhoneNumber: string,
    practiceStreetAddress: string,
    practiceCity: string,
    practiceZipCode: string,
    members?: string[],
    avatar?: string
  ): Promise<void> => {
    const { register: doctorRegister } = new DoctorAuthService();
    await doctorRegister({
      name,
      surname,
      password,
      email,
      phoneNumber,
      practiceName,
      practicePhoneNumber,
      practiceStreetAddress,
      practiceCity,
      practiceZipCode: String(practiceZipCode),
      members
    });
    await login(email, password);

    // setSession(
    //   {
    //     resourceType: 'Practitioner',
    //     id: user.id,
    //     name: user.name,
    //     email: user.email,
    //     avatar: user.avatar,
    //     clinic: user.clinic,
    //     role: ['Doctor']
    //   },
    //   rules
    // );
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user
    //   }
    // });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      method: 'JWT' as 'JWT',
      login,
      logout,
      register
    }),
    [state, login, logout, register]
  );

  //FIXIT
  //@ts-ignore
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

export interface IGoogleFit {
  readonly dailySteps: boolean;
  readonly workouts: boolean;
  readonly sleep: boolean;
  readonly calories: boolean;
  readonly weight: boolean;
  readonly bloodPressure: boolean;
  readonly heartRate: boolean;
  readonly bodyTemperature: boolean;
  readonly googleFitAuthorized: boolean;
}

export interface PatientName {
  readonly given: string;
  readonly family?: string;
}
