import { SwipeableDrawer, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import Activity from 'src/assets/images/data/Activity.png';
import Target from 'src/assets/images/data/Target.png';
import NotFound from 'src/assets/images/data/NoDataPatientTable.png';
import Theraphy from 'src/assets/images/data/Theraphy.png';

import { CodeableConcept, QuestionnaireResponse } from '@actimi/core-migration';
import {
  findPatientDesiredActivities,
  findPatientTarget,
  formatActivityGoalAnswer,
  formatTheraphyGoalAnswer
} from 'src/utils/Questionnaire';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { minHeight } from '@mui/system';
import { TFunction } from 'i18next';
import { localizableBodyParts } from 'src/utils/Patient';

interface ConditionsDrawerProps {
  readonly isDrawerOpen: boolean;
  readonly setIsDrawerOpen: (val: boolean) => void;
  readonly bodyParts: CodeableConcept[];
  readonly questionnaireResponse: QuestionnaireResponse;
}

export const linkIds = {
  theraphy: '/icd-1077-q',
  activity: '/icd-1077-d',
  target: '/icd-1077-f'
};

const ConditionsDrawer: React.FC<ConditionsDrawerProps> = ({
  isDrawerOpen,
  bodyParts,
  questionnaireResponse,
  setIsDrawerOpen,
  ...props
}) => {
  const { t } = useTranslation();
  const bodyPart = localizableBodyParts(bodyParts?.[0]?.text, t);
  const classes = useStyles();

  const generalConditions = [
    {
      condition: t('Health Problem'),
      conditionValue:
        bodyPart?.substring(bodyPart.indexOf(' ') + 1) + ' ' + t('Pain'),

      location: t('Location'),
      locationValue: bodyPart
    }
  ];

  const formattedGoalArray = [
    {
      goalType: t('Therapy Goal'),
      goalTypeValue:
        questionnaireResponse?.[0]?.item?.find(
          (item) => item?.linkId === linkIds.theraphy
        ) &&
        questionnaireResponse?.[0]?.item
          ?.find((item) => item?.linkId === linkIds.theraphy)
          .item.filter((x) => x?.answer?.[0]?.value?.boolean === true)
          .map((x) => formatTheraphyGoalAnswer(x?.linkId)),
      goalIcon: Theraphy,
      isVisible: questionnaireResponse?.[0]?.item?.find(
        (item) => item?.linkId === linkIds.theraphy
      )
        ? true
        : false
    },
    {
      goalType: t('Activity Goal'),
      goalTypeValue:
        questionnaireResponse?.[0]?.item?.find(
          (item) => item?.linkId === linkIds.activity
        ) &&
        questionnaireResponse?.[0]?.item?.find(
          (item) => item?.linkId === linkIds.activity
        ).answer?.[0].value.string,

      goalIcon: Activity,
      isVisible: questionnaireResponse?.[0]?.item?.find(
        (item) => item?.linkId === linkIds.activity
      )
        ? true
        : false
    },
    {
      goalType: t('Target Goal'),
      goalTypeValue: findPatientTarget(questionnaireResponse?.[0]?.item),
      goalIcon: Target,
      isVisible: questionnaireResponse?.[0]?.item?.find(
        (item) => item?.linkId === linkIds.target
      )
        ? true
        : false
    }
  ];

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      onOpen={() => setIsDrawerOpen(true)}
    >
      <Box
        p={4}
        sx={{
          width: {
            xs: '100vw',
            sm: '90vw',
            md: '70vw',
            lg: '40vw',
            xl: '30vw'
          }
        }}
      >
        {!questionnaireResponse ? (
          <SuspenseLoader />
        ) : (
          <React.Fragment>
            <Box>
              <Typography
                variant="h2"
                className={classes.title}
                color="MenuText"
              >
                {t('Health Problem')}
              </Typography>
            </Box>

            {generalConditions.map((item, index) => (
              <Box className={classes.generalConditionContainer} py={2} px={1}>
                <React.Fragment key={index}>
                  <Box className={classes.generalConditionItem}>
                    <Typography className={classes.itemName}>
                      {item.condition}:{' '}
                    </Typography>
                    <Typography className={classes.itemValue}>
                      {item.conditionValue}
                    </Typography>
                  </Box>
                  <Box className={classes.generalConditionItem}>
                    <Typography className={classes.itemName}>
                      {item.location}:{' '}
                    </Typography>
                    <Typography className={classes.itemValue}>
                      {item.locationValue}
                    </Typography>
                  </Box>
                </React.Fragment>
              </Box>
            ))}
            {/* </Carousel> */}
            <Typography className={classes.subTitle} mt={4} color="MenuText">
              {t('Goals')}
            </Typography>
            <Box className={classes.goalContainer} my={1}>
              {questionnaireResponse &&
                formattedGoalArray.map((item, index) => {
                  if (!item.isVisible) return null;

                  return (
                    <Box key={index} className={classes.goalCard} my={1.5}>
                      <Box>
                        <img src={item.goalIcon} className={classes.goalImg} />
                      </Box>
                      <Box className={classes.textContainer}>
                        <Typography className={classes.goalTitle}>
                          {item.goalType}
                        </Typography>
                        {typeof item.goalTypeValue === 'string' ? (
                          <Typography className={classes.goalValue}>
                            {item.goalTypeValue ?? t('Not Specified')}
                          </Typography>
                        ) : item?.goalTypeValue &&
                          item?.goalTypeValue?.length === 0 ? (
                          <Typography className={classes.goalValue}>
                            {t('Not Specified')}
                          </Typography>
                        ) : (
                          item?.goalTypeValue?.map((x) => (
                            <Typography className={classes.goalValue}>
                              {x ?? t('Not Specified')}
                            </Typography>
                          ))
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Typography className={classes.subTitle} color="MenuText">
              {t('Desired Activities')}
            </Typography>
            <Scrollbars
              autoHide
              style={{
                height: 190,
                marginTop: 12,
                border: '1px solid #E5E5E5',
                padding: 8,
                borderRadius: 5
              }}
            >
              {findPatientDesiredActivities(questionnaireResponse?.[0]?.item)
                ?.length > 0 ? (
                findPatientDesiredActivities(
                  questionnaireResponse?.[0]?.item
                ).map((item, index) => (
                  <Box
                    className={classes.desiredActivitiesContainer}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#F0F2F7' : 'white'
                    }}
                  >
                    <Typography className={classes.desiredActivityText}>
                      {item}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box className={classes.notFoundContainer}>
                  <img src={NotFound} className={classes.noDataImg} />
                  <Typography>{t('Patient-Dont-Have-Activities')}</Typography>
                </Box>
              )}
            </Scrollbars>
          </React.Fragment>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default ConditionsDrawer;

const useStyles = makeStyles(() => ({
  generalConditionContainer: {
    border: '1px solid #E5E5E5',

    borderRadius: 5,
    marginBottom: '32px'
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4%'
  },
  generalConditionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    paddingBottom: 8
  },
  itemName: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#8B92AB',
    fontFamily: 'Poppins'
  },
  itemValue: {
    marginLeft: 16,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232F48',
    fontFamily: 'Poppins'
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#232F48',
    marginBottom: 12
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#232F48',
    marginTop: 12
  },
  divider: {
    marginTop: 24
  },
  goalCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 12,
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    minWidth: '40%'
  },
  goalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  noDataImg: {
    width: 120,
    height: 120,
    marginBottom: 12
  },
  goalImg: {
    width: 48,
    height: 48,
    borderRadius: 24
  },
  goalTitle: {
    fontWeight: '300',
    fontSize: 12,
    lineHeight: '24px',
    fontFamily: 'Poppins',
    color: '#7E859D'
  },
  goalValue: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '24px',
    fontFamily: 'Poppins',
    color: '#232F48'
  },
  desiredActivitiesContainer: {
    height: 45,
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  desiredActivityText: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232F48',
    marginLeft: 12
  },
  textContainer: {
    minWidth: '50%'
  }
}));
