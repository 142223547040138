import { Divider, Grid, Stack, Typography, Button } from '@material-ui/core';
import React from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { makeStyles } from '@mui/styles';
import Mockups from 'src/assets/images/data/mockups.png';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Copy, Sms } from 'iconsax-react';
import GooglePlay from 'src/assets/images/data/gplay.png';
import AppStore from 'src/assets/images/data/apple-store.png';
import Logo from 'src/assets/images/data/logo-no-background.png';
import { Box } from '@mui/material';

export interface RedirectDeepLinkProps {}

const RedirectDeepLink: React.FC<RedirectDeepLinkProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const isRedirect = React.useRef<boolean>(false);
  const [isInit, setisInit] = React.useState<boolean>(false);
  const [verificationCode, setVerificationCode] = React.useState<string>();

  React.useEffect(() => {
    if (isMobile) {
      const params = new URLSearchParams(window.location.search);
      const verificationCode = params.get('code');
      const email = params.get('email');
      verificationCode && setVerificationCode(verificationCode);
      isRedirect.current = true;
      window.location.href = `mifysio://app/signup?code=${verificationCode}&email=${email}`;
    } else {
      const params = new URLSearchParams(window.location.search);
      const verificationCode = params.get('code');
      verificationCode && setVerificationCode(verificationCode);
    }
    setisInit((prev) => !prev);
  }, []);

  // React.useEffect(() => {
  //   if (isRedirect.current === false && isAndroid) {
  //     window.location.href =
  //       'https://play.google.com/store/apps/details?id=com.mifysio.app';
  //   }
  //   if (isRedirect.current === false && isIOS) {
  //     window.location.href = 'https://testflight.apple.com/join/QYKZSE1Y';
  //   }
  // }, [isInit]);

  const classes = useStyles();

  const SupportButton = ({ email }) => {
    return (
      <button className={classes.supportButton}>
        <Sms size="28" color="#232F48" />
        <Typography className={classes.supportText}>{email}</Typography>
      </button>
    );
  };

  const CopyToClipboardComponent = ({ text }) => {
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    };

    return (
      <React.Fragment>
        <CopyToClipboard text={text}>
          <button onClick={handleCopy} className={classes.copyButton}>
            {!copied && <Copy size="28" color="#232F48" />}
            <Typography
              className={classes.codeText}
              sx={{
                letterSpacing: copied ? '0.5px' : '4px'
              }}
            >
              {copied
                ? t('Copied Code')
                : text?.slice(0, 3) + '-' + text?.slice(3, 6)}
            </Typography>
          </button>
        </CopyToClipboard>
      </React.Fragment>
    );
  };

  const StoreElements = () => {
    return (
      <Stack className={classes.storeContainer}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Stack
            className={classes.storeButton}
            onClick={() => {
              window.location.href =
                'https://play.google.com/store/apps/details?id=com.mifysio.app';
            }}
          >
            <img
              alt="google-play"
              src={GooglePlay}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          </Stack>
          <Stack
            className={classes.storeButton}
            onClick={() => {
              window.location.href =
                'https://testflight.apple.com/join/QYKZSE1Y';
            }}
          >
            <img
              alt="app-store"
              src={AppStore}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const InfoTextContainer: React.FC<{ title: string; info: string }> = ({
    title,
    info
  }) => {
    return (
      <Stack>
        <Typography className={classes.title}>{title}</Typography>
        <Typography>{info}</Typography>
      </Stack>
    );
  };

  const LogoContainer = () => {
    return (
      <Stack
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Stack
          width="100%"
          pl={4}
          alignItems="flex-start"
          sx={{ height: { xs: 0, lg: 100 } }}
        >
          <Box
            sx={{
              width: {
                xs: 90,
                sm: 120,
                md: 170
              },
              height: {
                xs: 22,
                sm: 32,
                md: 44
              }
            }}
          >
            <img
              alt="mf-logo"
              src={Logo}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Stack>
        <Box
          sx={{
            width: {
              xs: 300,
              sm: 320,
              md: 550,
              lg: 600
            },
            height: {
              xs: 225,
              sm: 250,
              md: 450,
              lg: 500
            }
          }}
        >
          <img
            alt="mockups"
            src={Mockups}
            style={{
              width: '100%'
            }}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <LogoContainer />
      </Grid>
      <Grid item xs={12} md={9} className={classes.rightContainer}>
        <Stack className={classes.infoContainer}>
          <InfoTextContainer
            title={t('Invitation code')}
            info={t('Invitation info')}
          />
          <CopyToClipboardComponent text={verificationCode} />

          <Divider
            sx={{
              mt: '36px'
            }}
          />
        </Stack>
        <Stack className={classes.infoContainer}>
          <InfoTextContainer
            title={t('Download physiomate')}
            info={t('Download info')}
          />
          <StoreElements />

          <Divider
            sx={{
              mt: '36px'
            }}
          />
        </Stack>
        <Stack className={classes.infoContainer}>
          <InfoTextContainer title={t('Contact us')} info={t('Contact info')} />
          <SupportButton email={'support@physiomate.com'} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RedirectDeepLink;
const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    width: 230,
    height: 30,
    marginRight: 12,
    color: '#FFF'
  },
  leftContainer: {
    height: '100vh',
    backgroundColor: '#F5F8FB'
  },
  rightContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#FFF'
  },
  infoContainer: {
    width: '85%'
  },

  copyButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    borderColor: '#DCDFEA',
    height: 44,
    width: '170px',
    marginBottom: '12px',
    marginTop: '12px'
  },
  supportButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    borderColor: '#DCDFEA',
    height: 44,
    width: '240px',
    marginBottom: '12px',
    marginTop: '12px'
  },
  codeText: {
    color: '#232F48',
    fontSize: '14px',
    fontWeight: '500',
    alignSelf: 'center'
  },
  supportText: {
    color: '#1C8ECE',
    fontSize: '14px',
    fontWeight: '500',
    alignSelf: 'center',
    textDecoration: 'underline'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#232F48',
    marginBottom: '12px'
  },
  info: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#232F48',
    marginBottom: '12px'
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    marginBottom: '12px',
    marginTop: '12px'
  },
  storeButton: {
    width: '100%',
    height: 44,
    borderRadius: '8px',
    marginRight: '12px',
    backgroundColor: 'transparent'
  }
}));
