import { useState, ReactNode, createContext } from 'react';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  chatSidebarToggle: boolean;
  toggleChatSidebar: () => void;
  closeChatSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

type Props = {
  children: ReactNode;
};

export function SidebarProvider({ children }: Props) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [chatSidebarToggle, setChatSidebarToggle] = useState(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const toggleChatSidebar = () => {
    setChatSidebarToggle(!chatSidebarToggle);
  };

  const closeChatSidebar = () => {
    setChatSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        chatSidebarToggle,
        toggleChatSidebar,
        closeChatSidebar
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
