import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { ITableItem } from 'src/models/dashboard-table';
import { IObservation } from '../models/observation/IObservation';
import { ObservationCode } from '../models/observation/enums/ObservationCode';
import {
  DashboardTableFilter,
  PatientDetailsService
} from '@actimi/core-migration';

export const sortFieldMap = {
  symptoms: ObservationCode.SYMPTOM,
  bloodPressure: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
  heartRate: ObservationCode.HEART_RATE,
  bodyWeight: ObservationCode.BODY_WEIGHT,
  ecg: ObservationCode.ECG,
  lastMeasured: 'lastMeasurementDate'
};

interface ISortParams {
  field: keyof typeof sortFieldMap;
  order: 'asc' | 'desc';
}
export interface IFilterParams {
  searchArg: string;
  taskFilter: any;
}

export interface MainDashboardState {
  isLoading: boolean;
  total: number;
  filter: IFilterParams;
  sort: ISortParams | null;
  tableData: ITableItem[] | any[];
  tableInactiveData: ITableItem[] | any[];
  inactiveTotal: number;
}

const initialState: MainDashboardState = {
  isLoading: true,
  tableData: [],
  tableInactiveData: [],
  inactiveTotal: 0,
  total: 0,
  filter: {
    searchArg: '',
    taskFilter: DashboardTableFilter.ALL_PATIENTS
  },
  sort: null
};

const slice = createSlice({
  name: 'main-dashboard',
  initialState,
  reducers: {
    setFilter(
      state: MainDashboardState,
      action: PayloadAction<{
        filter: IFilterParams;
      }>
    ) {
      const { filter } = action.payload;
      state.filter = filter;
    },
    setSort(
      state: MainDashboardState,
      actions: PayloadAction<ISortParams | null>
    ) {
      state.sort = actions.payload;
    },
    setLoading(
      state: MainDashboardState,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) {
      const { loading } = action.payload;
      state.isLoading = loading;
    },
    updateTableDataLastMeasured(
      state: MainDashboardState,
      action: PayloadAction<{
        observation: IObservation;
      }>
    ) {},
    updateTableDataSymptoms(
      state: MainDashboardState,
      action: PayloadAction<{
        count: number;
        subject: string;
      }>
    ) {},
    updateTableDataItem(
      state: MainDashboardState,
      action: PayloadAction<{
        tableItem: ITableItem;
      }>
    ) {
      const { tableItem } = action.payload;
      let found = false;
      for (let i = 0; i < state.tableData.length; i++) {
        //TODO:Batuhan
        //@ts-ignore
        // if (state.tableData[i].patient === tableItem.patient) {
        //   state.tableData[i] = tableItem;
        //   found = true;
        //   break;
        // }
      }
      if (!found) {
        state.tableData.push(tableItem);
      }
    },
    getInactiveTableData(
      state: any,
      action: PayloadAction<{
        tableData: ITableItem[];
        total: number;
      }>
    ) {
      const { tableData, total } = action.payload;
      state.tableInactiveData = tableData;
      state.inactiveTotal = total;
      // state.isLoading = false;
    },
    getTableData(
      state: MainDashboardState,
      action: PayloadAction<{
        tableData: ITableItem[];
        total: number;
      }>
    ) {
      const { tableData, total } = action.payload;
      state.tableData = tableData;
      state.total = total;
      state.isLoading = false;
    },
    reset(state: MainDashboardState) {
      Object.assign(state, initialState);
    }
  }
});

export const reducer = slice.reducer;

export const setSort = slice.actions.setSort;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setFilter =
  (filter: IFilterParams): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setFilter({
        filter
      })
    );
  };

export const setLoading =
  (loading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setLoading({
        loading
      })
    );
  };

export const getTableData =
  (
    meId: string,
    organizationId: string,
    sort,
    order,
    searchArg,
    taskFilter,
    whenDone: (participantIds?: string[]) => void | undefined,
    count: number = 30,
    page: number = 1
  ): AppThunk =>
  async (dispatch) => {
    const { getMifysioTableData } = new PatientDetailsService();

    try {
      const resp = await getMifysioTableData(
        organizationId,
        count,
        page,
        sort,
        order,
        searchArg

        // taskFilter
      );
      dispatch(
        slice.actions.getTableData({
          // @ts-ignore
          tableData: resp.items,
          total: resp.total
        })
      );
      if (whenDone) {
        //TODO:Batuhan
        //@ts-ignore
        whenDone(resp.items.map((x) => x.patient));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateTableDataLastMeasured =
  (observation: IObservation): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updateTableDataLastMeasured({
        observation
      })
    );
  };

export const updateTableItem =
  (tableItem: ITableItem): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateTableDataItem({ tableItem }));
  };

export const updateTableDataSymptoms =
  (count: number, subject: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updateTableDataSymptoms({
        count,
        subject
      })
    );
  };

export default slice;
