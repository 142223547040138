import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import { Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        
        
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function LogoMifysio() {
  const { t } = useTranslation();
  return (
    <LogoWrapper to="/">
      {/* <LogoSignWrapper> */}
      <Stack
        sx={{
          width: 390
        }}
      >
        <img
          alt=""
          src={'/static/images/logo/mifysio-logo-text.png'}
          style={{
            width: '100%',
            height: '18rem',
            objectFit: 'contain'
          }}
        />
        <Typography
          variant="h1"
          align="center"
          sx={{
            mb: 12,
            fontSize: 28,
            fontWeight: '600',
            color: '#232F48'
          }}
        >
          {t('welcome-sidebar', {
            appName: 'Physiomate'
          })}
        </Typography>
      </Stack>
    </LogoWrapper>
  );
}

export default LogoMifysio;
