import {
  acuteWorkloadGraphFactory,
  observationGraphFactory
} from './../utils/GraphicData';
import { AppThunk, AppDispatch } from './../store/index';
import {
  AcuteChronicWorkloadService,
  handleTimeSpanIntervalChange,
  ObservationCode,
  ObservationService,
  QuestionnaireScoreService,
  TimeSpan
} from '@actimi/core-migration';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface IChartState {
  allGraphData: {
    painLevelGraphData: any;
    fatigueGraphData: any;
    moodGraphData: any;
    stressLevelGraphData: any;
    sleepQualityGraphData: any;
    muscleSorenessGraphData: any;
  };
  allOverallData: {
    painLevelGraphDataOverall: number;
    fatigueGraphDataOverall: number;
    moodGraphDataOverall: number;
    stressLevelGraphDataOverall: number;
    sleepQualityGraphDataOverall: number;
    muscleSorenessGraphDataOverall: number;
  };
  ACWR: number | undefined;
  painLevelGraphData: any;
  fatigueGraphData: any;
  moodGraphData;
  stressLevelGraphData: any;
  sleepQualityGraphData: any;
  muscleSorenessGraphData: any;
  questionnaireScoreGraphData: any;
  formattedQuestionnaireData: any;
  acuteWorkloadGraph: any;
  painDataOverall: number | string;
  questionnaireDataLength: number | string;
  acuteWorkloadOverall: number | string;
  currentLatestScore: string;
  latestGraphValues: any;
  isHaveGraphValue: boolean;
}

const initialState: IChartState = {
  allGraphData: {
    painLevelGraphData: undefined,
    fatigueGraphData: undefined,
    moodGraphData: undefined,
    stressLevelGraphData: undefined,
    sleepQualityGraphData: undefined,
    muscleSorenessGraphData: undefined
  },
  allOverallData: {
    painLevelGraphDataOverall: undefined,
    fatigueGraphDataOverall: undefined,
    moodGraphDataOverall: undefined,
    stressLevelGraphDataOverall: undefined,
    sleepQualityGraphDataOverall: undefined,
    muscleSorenessGraphDataOverall: undefined
  },
  isHaveGraphValue: false,
  ACWR: undefined,
  painLevelGraphData: undefined,
  stressLevelGraphData: undefined,
  moodGraphData: undefined,
  sleepQualityGraphData: undefined,
  muscleSorenessGraphData: undefined,
  fatigueGraphData: undefined,
  questionnaireScoreGraphData: undefined,
  formattedQuestionnaireData: undefined,
  painDataOverall: undefined,
  questionnaireDataLength: undefined,
  acuteWorkloadGraph: undefined,
  acuteWorkloadOverall: undefined,
  currentLatestScore: undefined,
  latestGraphValues: undefined
};

const slice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setPainLevelGraphData(state, action: PayloadAction<any>) {
      state.painLevelGraphData = action.payload;
    },

    setQuestionnaireScoreGraphData(state, action: PayloadAction<any>) {
      state.questionnaireScoreGraphData = action.payload;
    },
    setFormattedQuestionnaireData(state, action: PayloadAction<any>) {
      const cloneAction = _.cloneDeep(action.payload);
      state.formattedQuestionnaireData = cloneAction;
    },

    setPainDataLength(state, action: PayloadAction<number | string>) {
      if (isNaN(action.payload as number)) {
        state.painDataOverall = '--';
      } else state.painDataOverall = action.payload;
    },
    setQuestionnaireDataLength(state, action: PayloadAction<number | string>) {
      state.questionnaireDataLength = action.payload.toString().slice(0, 4);
    },

    setACWR(state, action: PayloadAction<number>) {
      state.ACWR = action.payload;
    },
    setAcuteWorkloadGraph(state, action: PayloadAction<any>) {
      state.acuteWorkloadGraph = action.payload;
    },
    setAcuteWorkloadOverall(state, action: PayloadAction<any>) {
      state.acuteWorkloadOverall = action.payload;
    },
    setCurrentLatestScore(state, action: PayloadAction<string>) {
      state.currentLatestScore = action.payload;
    },
    setFatigueGraphData(state, action: PayloadAction<any>) {
      state.fatigueGraphData = action.payload;
    },
    setMuscleSorenessGraphData(state, action: PayloadAction<any>) {
      state.muscleSorenessGraphData = action.payload;
    },
    setSleepQualityGraphData(state, action: PayloadAction<any>) {
      state.sleepQualityGraphData = action.payload;
    },
    setStressLevelGraphData(state, action: PayloadAction<any>) {
      state.stressLevelGraphData = action.payload;
    },
    setMoodGraphData(state, action: PayloadAction<any>) {
      state.moodGraphData = action.payload;
    },
    setIsHaveGraphData(state, action: PayloadAction<boolean>) {
      state.isHaveGraphValue = action.payload;
    },
    setAllGraphData(state, action: PayloadAction<any>) {
      state.allGraphData = action.payload;
    },
    setAllOverallData(state, action: PayloadAction<any>) {
      state.allOverallData = action.payload;
    },
    setLatestGraphValues(state, action: PayloadAction<any>) {
      state.latestGraphValues = action.payload;
    },
    reset(state: IChartState) {
      Object.assign(state, initialState);
    }
  }
});

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

const getGraphicDataCreatedAtParam = (minDate: string, maxDate: string) => {
  return `le${maxDate}&ge${minDate}`;
};

export const getAllObservationGraphData =
  (
    selectedTimeSpan: TimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const [
      painLevel,
      mood,
      fatigue,
      muscleSoreness,
      sleepQuality,
      stressLevel
    ] = await Promise.all([
      await getGraphicInformation(organizationId, {
        code: ObservationCode.PAIN,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      }),
      await getGraphicInformation(organizationId, {
        code: ObservationCode.MOOD,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      }),
      await getGraphicInformation(organizationId, {
        code: ObservationCode.FATIGUE,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      }),
      await getGraphicInformation(organizationId, {
        code: ObservationCode.MUSCLE_SORENESS,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      }),
      await getGraphicInformation(organizationId, {
        code: ObservationCode.SLEEP_QUALITY,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      }),
      await getGraphicInformation(organizationId, {
        code: ObservationCode.STRESS_LEVEL,
        timespan: selectedTimeSpan,
        _createdAt: newCreatedAt,
        subject: `Patient/${userId}`
      })
    ]);

    dispatch(
      slice.actions.setAllGraphData({
        painLevelGraphData: observationGraphFactory(
          painLevel,
          selectedTimeSpan,
          newCreatedAt
        ),
        fatigueGraphData: observationGraphFactory(
          fatigue,
          selectedTimeSpan,
          newCreatedAt
        ),
        moodGraphData: observationGraphFactory(
          mood,
          selectedTimeSpan,
          newCreatedAt
        ),
        stressLevelGraphData: observationGraphFactory(
          stressLevel,
          selectedTimeSpan,
          newCreatedAt
        ),
        sleepQualityGraphData: observationGraphFactory(
          sleepQuality,
          selectedTimeSpan,
          newCreatedAt
        ),
        muscleSorenessGraphData: observationGraphFactory(
          muscleSoreness,
          selectedTimeSpan,
          newCreatedAt
        )
      })
    );
    dispatch(
      slice.actions.setAllOverallData({
        painLevelGraphDataOverall: Math.round(
          observationGraphFactory(painLevel, selectedTimeSpan, newCreatedAt)
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / painLevel.values.length
        ),
        fatigueGraphDataOverall: Math.round(
          observationGraphFactory(fatigue, selectedTimeSpan, newCreatedAt)
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / fatigue.values.length
        ),
        moodGraphDataOverall: Math.round(
          observationGraphFactory(mood, selectedTimeSpan, newCreatedAt)
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / mood.values.length
        ),
        stressLevelGraphDataOverall: Math.round(
          observationGraphFactory(stressLevel, selectedTimeSpan, newCreatedAt)
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / stressLevel.values.length
        ),
        sleepQualityGraphDataOverall: Math.round(
          observationGraphFactory(sleepQuality, selectedTimeSpan, newCreatedAt)
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / sleepQuality.values.length
        ),
        muscleSorenessGraphDataOverall: Math.round(
          observationGraphFactory(
            muscleSoreness,
            selectedTimeSpan,
            newCreatedAt
          )
            ?.final.filter((x) => x !== undefined)
            ?.reduce((a, b) => a + b, 0) / muscleSoreness.values.length
        )
      })
    );

    whenDone?.();
  };

export const getPainLevelGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.PAIN,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    dispatch(
      slice.actions.setPainDataLength(
        Math.round(sumOfData / response.values.length)
      )
    );

    dispatch(slice.actions.setPainLevelGraphData(data));
    whenDone?.();
  };

export const getFatigueGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.FATIGUE,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    // const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    // dispatch(
    //   slice.actions.setPainDataLength(
    //     Math.round(sumOfData / response.values.length)
    //   )
    // );

    dispatch(slice.actions.setFatigueGraphData(data));
    whenDone?.();
  };

export const getMuscleSorenessGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.MUSCLE_SORENESS,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    // const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    // dispatch(
    //   slice.actions.setPainDataLength(
    //     Math.round(sumOfData / response.values.length)
    //   )
    // );

    dispatch(slice.actions.setMuscleSorenessGraphData(data));
    whenDone?.();
  };

export const getSleepQualityGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.SLEEP_QUALITY,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    // const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    // dispatch(
    //   slice.actions.setPainDataLength(
    //     Math.round(sumOfData / response.values.length)
    //   )
    // );

    dispatch(slice.actions.setSleepQualityGraphData(data));
    whenDone?.();
  };

export const getStressLevelGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.STRESS_LEVEL,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    // const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    // dispatch(
    //   slice.actions.setPainDataLength(
    //     Math.round(sumOfData / response.values.length)
    //   )
    // );

    dispatch(slice.actions.setStressLevelGraphData(data));
    whenDone?.();
  };

export const getMoodGraphData =
  (
    selectedTimeSpan,
    userId: string,
    organizationId: string,
    _createdAt,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getGraphicInformation } = new ObservationService();
    const newCreatedAt = getGraphicDataCreatedAtParam(
      _createdAt,
      handleTimeSpanIntervalChange(selectedTimeSpan, _createdAt, true)
    );
    const response = await getGraphicInformation(organizationId, {
      code: ObservationCode.MOOD,
      timespan: selectedTimeSpan,
      _createdAt: newCreatedAt,
      subject: `Patient/${userId}`
    });

    const data = observationGraphFactory(
      response,
      selectedTimeSpan,
      newCreatedAt
    );

    // const sumOfData = data?.final?.reduce((a, b) => a + b, 0);
    // dispatch(
    //   slice.actions.setPainDataLength(
    //     Math.round(sumOfData / response.values.length)
    //   )
    // );

    dispatch(slice.actions.setMoodGraphData(data));
    whenDone?.();
  };

export const calculateOverallScoreOfQuestionnaire =
  (data) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setQuestionnaireDataLength(data));
  };

// export const getQuestionnaireScoreGraphData =
//   (body: GetQuestionnaireScoreQuery, whenDone?: () => void): AppThunk =>
//   async (dispatch: AppDispatch) => {
//     // const response = await MiddlewareService.getQuestionnaireScoreGraph(body);

//     // dispatch(slice.actions.setQuestionnaireScoreGraphData(response));
//     whenDone?.();
//   };

export const getAcuteChronicWorkloadGraph =
  (
    owner: string,
    organizationId: string,
    timespan: TimeSpan,
    createdAt: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getAcuteChronicWorkloadGraph } = new AcuteChronicWorkloadService();
    dispatch(slice.actions.setAcuteWorkloadOverall('--'));
    const newCreatedAt = getGraphicDataCreatedAtParam(
      createdAt,
      handleTimeSpanIntervalChange(timespan, createdAt, true)
    );
    const response = await getAcuteChronicWorkloadGraph({
      owner: `Patient/${owner}`,
      timespan: timespan,
      organizationId: organizationId,
      _createdAt: newCreatedAt
    });
    const data = acuteWorkloadGraphFactory(response, timespan, newCreatedAt);

    const sumOfData = data?.final
      ?.filter((x) => x !== undefined)
      ?.reduce((a, b) => a + b.ctl, 0);
    dispatch(
      slice.actions.setAcuteWorkloadOverall(
        isNaN(sumOfData / data?.final?.filter((x) => x !== undefined).length)
          ? '--'
          : sumOfData / data?.final?.filter((x) => x !== undefined).length
      )
    );
    //@ts-ignore
    dispatch(slice.actions.setACWR(response?.latestMeasuredValue?.acwr));
    dispatch(slice.actions.setAcuteWorkloadGraph(data));

    whenDone?.();
  };

export const getLatestGraphValues =
  (owner: string, organizationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getObservations } = new ObservationService();
    const { getLatestQuestionnaireScore } = new QuestionnaireScoreService();

    let data = await Promise.all([
      await getObservations({
        _count: 1,
        _sort: '-_createdAt',
        code: ObservationCode.PAIN,
        subject: `Patient/${owner}`,
        //@ts-ignore
        'subject:Patient.organization': organizationId as string
      }),
      await getLatestQuestionnaireScore(owner, organizationId)
      // await getLatestAcuteChronicWorkload(owner, organizationId)
    ]);
    dispatch(
      slice.actions.setLatestGraphValues({
        latestPain: data[0],
        latestQuestionnaireScore: data[1]
      })
    );

    whenDone?.();
  };

export const reducer = slice.reducer;

export default slice;
