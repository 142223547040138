export enum ObservationCode {
  BLOOD_PRESSURE_HEART_RATE = '8885-6',
  BLOOD_GLUCOSE = '2340-8',
  KETONE = '33903-6',
  SYMPTOM = '45853-9',
  CHOLESTEROL = '12183-0',
  TRIGLYCERIDES = '33903-6',
  URIC_ACID = '25548-9',
  LACTATE = '32693-4',
  ECG_HEART_RATE = '8886-4',
  HEART_RATE = '8867-4',
  DIASTOLIC_BLOOD_PRESSURE = '8462-4',
  SYSTOLIC_BLOOD_PRESSURE = '8480-6',
  OXYGEN_SATURATION = '2708-6',
  OXYGEN_SATURATION_GRAPH = '2708',
  BODY_TEMPERATURE = '8310-5',
  ECG = '131328',
  RESPIRATORY_RATE = '9279-1',
  HEARTH_RATE_VARIABILITY = '80404-7',
  MOOD = '52497-5',
  BODY_HEIGHT = '8302-2',
  BODY_WEIGHT = '29463-7',
  GRATITUDE = '91262-6',
  STEPS = '41950-7',
  BURNED_CALORIES = '41981-2',
  PAIN = '94085-8',
  QUESTIONNAIRE = '45853-9'
}
