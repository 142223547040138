import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPatientProfile } from 'src/models/general/IPatientProfile';
import { AppDispatch, AppThunk } from 'src/store';
import { CodeableConcept, ProcedureService } from '@actimi/core-migration';
import { batch } from 'react-redux';

export type PatientProfileMap = { [patientId: string]: IPatientProfile };

const { getProcedureByPatientId, patchProcedureCategory } =
  new ProcedureService();

export interface IPatientsProfileState {
  patients: PatientProfileMap;
  patientIds: string[];
  isLoading: boolean;
  patientId: string;
  saltPatient: any;
  tagProcedures: any;
  procedureCategory: CodeableConcept;
}

const initialState: IPatientsProfileState = {
  patients: {},
  patientIds: [],
  isLoading: true,
  patientId: undefined,
  saltPatient: undefined,
  tagProcedures: undefined,
  procedureCategory: undefined
};

const slice = createSlice({
  name: 'patients-profile',
  initialState,
  reducers: {
    setPatients(
      state,
      action: PayloadAction<{
        patients: PatientProfileMap;
      }>
    ) {
      state.patients = action.payload.patients;
      state.patientIds = Object.keys(state.patients);
      state.isLoading = false;
    },
    setPatient(
      state,
      action: PayloadAction<{
        patient: IPatientProfile;
      }>
    ) {
      const { patient } = action.payload;

      state.patients[patient.id] = patient;
      state.patientIds = Object.keys(state.patients);
    },
    setIsLoading(
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) {
      state.isLoading = action.payload.isLoading;
    },
    setTagProcedures(
      state,
      action: PayloadAction<{
        patientId: string;
      }>
    ) {
      state.tagProcedures = action.payload;
    },

    setSaltPatient(state, action: PayloadAction<any>) {
      state.saltPatient = action.payload;
    },
    setPatientId(state, action: PayloadAction<string>) {
      state.patientId = action.payload;
    },

    reset(state: IPatientsProfileState) {
      Object.assign(state, initialState);
    },
    setProcedureCategory(state, action: PayloadAction<any>) {
      state.procedureCategory = action.payload;
    }
  }
});

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchProcedures =
  (patientId: string, whenDone?: () => void) =>
  async (dispatch: AppDispatch) => {
    const procedures = await getProcedureByPatientId(patientId);
    batch(() => {
      dispatch(
        slice.actions.setTagProcedures(procedures?.entry?.[0]?.resource)
      );

      dispatch(
        slice.actions.setProcedureCategory(
          procedures?.entry?.[0]?.resource?.category
        )
      );
    });
    whenDone?.();
  };
export const patchProcedure =
  (proceduresId: string, category: CodeableConcept, whenDone?: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const patchProcedures = await patchProcedureCategory(
        proceduresId,
        category
      );
      dispatch(slice.actions.setProcedureCategory(patchProcedures.category));
    } catch (error) {
      console.log(error);
    }
  };

export const setPatients = (patientsList: IPatientProfile[]) => {
  return async (dispatch) => {
    dispatch(slice.actions.setSaltPatient(patientsList));
    const patients = patientsList.reduce((data: PatientProfileMap, patient) => {
      data[patient.id] = patient;
      return data;
    }, {});
    dispatch(slice.actions.setPatients({ patients }));
  };
};

export const updatePatient =
  (patient: IPatientProfile): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPatient({ patient }));
  };

export const reducer = slice.reducer;

export default slice;
