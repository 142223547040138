import React, {
  forwardRef,
  useState,
  useEffect,
  FocusEventHandler
} from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { CloseCircle, TickCircle } from 'iconsax-react';

interface TextInputProps {
  className?: string;
  disabled?: boolean;
  isDisabledText?: boolean;
  value: number | string | unknown;
  setValue?: Function;
  isHalf?: boolean;
  onChange?: (event: object) => void;
  rightIcon?: React.ReactNode;
  autoFocus?: boolean;
  defaultValue?: string | number | unknown;
  id?: string;
  type?: string;
  name?: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  inputType: string;
  required?: boolean;
  inputProps?: Object;
  variant?: 'standard' | 'filled' | 'outlined';
  sx?: Object;
  size?: 'medium' | 'small';
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | React.RefObject<HTMLDivElement>;
  fullWidth?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  helperText?: React.ReactNode;
  validatePattern?: string;
  error?: boolean;
  validationInput?: boolean;
  errorMessage?: string;
  margin?: 'dense' | 'normal' | 'none';
  inputWidth?:
    | number
    | string
    | { xs: string; sm: string; md: string; lg: string; xl: string };
  parametersValue?: boolean;
  inputHeight?: number | string;
}

const TextInput = forwardRef(
  (
    {
      validatePattern,
      value,
      setValue,
      rightIcon,
      validationInput,
      error,
      size,
      disabled,
      errorMessage,
      isHalf,
      inputType,
      helperText,
      isDisabledText,
      defaultValue,
      parametersValue,
      ...rest
    }: TextInputProps,
    inputRef
  ) => {
    const [validation, setValidation] = useState<{
      isError: boolean;
      errorMessage: string;
    }>({ isError: false, errorMessage: '' });

    useEffect(() => {
      let currentValue;
      if (validatePattern && value) {
        currentValue = String(value);
        !currentValue.match(validatePattern)
          ? setValidation({ isError: true, errorMessage })
          : setValidation({ isError: false, errorMessage: '' });
      }
    }, [errorMessage, validatePattern, value]);

    return (
      <TextField
        size={size ?? 'small'}
        error={error ?? validation.isError}
        value={parametersValue ? undefined : value}
        disabled={disabled}
        defaultValue={defaultValue}
        type={inputType ?? 'string'}
        InputProps={{
          endAdornment:
            !validatePattern &&
            value &&
            setValue &&
            !disabled &&
            !rightIcon &&
            !error &&
            !validationInput ? (
              <InputAdornment position="start">
                <CloseCircle
                  style={{ cursor: 'pointer' }}
                  onClick={() => setValue('')}
                  color={'#8B92AB'}
                />
              </InputAdornment>
            ) : !validation.isError &&
              value &&
              !disabled &&
              !rightIcon &&
              validationInput &&
              inputType !== 'number' &&
              !error ? (
              <InputAdornment
                style={{ position: 'absolute', right: 7 }}
                position="end"
              >
                <TickCircle color={'#5AD0A6'} />
              </InputAdornment>
            ) : (
              <InputAdornment position="start">{rightIcon}</InputAdornment>
            ),
          ...rest?.inputProps
        }}
        inputRef={inputRef}
        variant="outlined"
        sx={{
          '& .MuiOutlinedInput-root': {
            color: '#232F48',
            width: rest.inputWidth ?? 340,

            height: rest.inputHeight ?? '2.7em',
            '& fieldset': {
              borderRadius: 1,
              borderWidth: 1,
              borderColor:
                (validatePattern &&
                  value &&
                  !validation.isError &&
                  !disabled) ||
                (!error && value && !disabled && validationInput)
                  ? `#5AD0A6 !important`
                  : (validatePattern && validation.isError) ||
                    (error && validationInput)
                  ? `#FC6262 !important`
                  : `#8B92AB !important`
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1,
              borderColor:
                (validatePattern && value && !validation.isError) ||
                (!error && value && validationInput)
                  ? `#5AD0A6 !important`
                  : (validatePattern && validation.isError) ||
                    (error && validationInput)
                  ? `#FC6262 !important`
                  : `#26BDD8 !important`
            }
          },
          '& .MuiFormHelperText-root': {
            color:
              (validatePattern && value && !validation.isError) ||
              (!error && value && validationInput)
                ? '#5AD0A6'
                : (validatePattern && validation.isError) || error
                ? '#FC6262'
                : '#8B92AB',
            fontSize: 12,
            fontWeight: 400,
            marginBottom: isDisabledText ? 2 : 1.5
          },
          '& .MuiFormLabel-root': {
            color:
              (validatePattern && !validation.isError && value) ||
              (validationInput &&
                value &&
                !error &&
                !disabled &&
                !isDisabledText &&
                '#5AD0A6')
          },
          ...rest.sx?.toString
        }}
        {...rest}
        helperText={
          validatePattern && validation.isError
            ? validation.errorMessage
            : helperText
        }
      />
    );
  }
);

export default TextInput;
