import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import CollapsedSidebarLayout from './layouts/CollapsedSidebarLayout';
import RedirectDeepLink from './content/deeplink/redirect-link';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Analytics = Loader(lazy(() => import('src/content/dashboards/Patient')));

const Dashboard = Loader(
  lazy(() => import('src/content/dashboards/Dashboard'))
);

const Database = Loader(lazy(() => import('src/content/dashboards/Database')));

const Settings = Loader(lazy(() => import('src/content/dashboards/Settings')));

// Applications

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);

const RegisterCover = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Cover'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ResetPassword'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: PartialRouteObject[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
      {
        path: 'recover-password',
        element: <RecoverPassword />
      },
      {
        path: 'reset-password/:token',
        element: <ResetPassword />
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'register-cover',
        element: <RegisterCover />
      }
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/main-dashboard" />
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/main-dashboard" replace />
      },
      {
        path: 'main-dashboard',
        element: <Dashboard />
      },
      {
        path: 'database',
        element: <Database />
      },
      {
        path: 'analytics',
        children: [
          {
            path: '/',
            element: <Dashboard />
          },
          {
            path: 'user/:userId',
            element: <Analytics />
          }
        ]
      },
      {
        path: 'settings',
        element: <Settings />
      }
    ]
  },
  {
    path: 'applications',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/applications/messenger" replace />
      }
    ]
  },
  {
    path: 'deeplink',
    element: (
      <Guest>
        <RedirectDeepLink />
      </Guest>
    )
  }
];

export default routes;
