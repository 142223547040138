import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function JustCardioTextLogo() {
  return (
    <LogoWrapper to="/">
      {/* <LogoSignWrapper> */}
      <img
        alt=""
        src={'/static/images/logo/mifysio.png'}
        style={{
          width: 90,
          marginBottom: 12,
          paddingRight: 24,
          objectFit: 'contain'
        }}
      />
    </LogoWrapper>
  );
}

export default JustCardioTextLogo;
