import { PatientProgramState } from './../content/dashboards/Patient/components/Charts/utils/ChartData';
import { TFunction } from 'i18next';
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { CarePlan, Task, TaskInput } from '@actimi/core-migration';
import moment from 'moment';
import { ExerciseData } from 'src/components/PatientProfile/ExerciseAccordion';

/* eslint-disable array-callback-return */
export const calculateExerciseTime = (values: {
  tempo: string;
  repetitions: string;
  sets: string;
}) => {
  const { tempo, repetitions, sets } = values;
  let sumOfTempo = 0;
  let durationBasedSeconds = 0;

  if (tempo.includes('-')) {
    tempo.split('-').map((tempo) => {
      sumOfTempo = sumOfTempo + Number(tempo);
    });
  } else {
    sumOfTempo = Number(tempo);
  }

  const exerciseTime = sumOfTempo * Number(repetitions) * Number(sets);

  return exerciseTime;
};

export const getExerciseBase = (t: TFunction, input: TaskInput[]) => {
  const result = input
    .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
    .map((q) => {
      return q.value.Quantity.id.includes('-') ? t('Tempo') : t('Duration');
    })
    .filter((x) => x !== undefined)
    .slice(-1)[0];

  return result;
};

export const getExercisePlanDuration = (plan) => {
  let totalTime = 0;
  let countOfTask = 0;
  plan.map((item) => {
    countOfTask = item.tasks.length;
    item.tasks.forEach((i) => {
      let repetition = 0;
      let rest = 0;
      let sets = 0;
      let tempo = '';
      let tempoSecond = 0;
      let durationBasedSeconds = 0;
      const tempoValue = i.input
        .filter((x) => x.type.coding?.[0].code === 'requestedTempo')
        .slice(-1)[0];

      if (tempoValue?.value.string) {
        if (tempoValue.value.string.includes('-')) {
          tempo = tempoValue.value.string;
        } else {
          durationBasedSeconds = Number(tempoValue.value.string);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        i.input
          .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
          .map((q) => {
            if (q.value.Quantity.id.includes('-')) {
              tempo = q.value.Quantity.id;
            } else {
              durationBasedSeconds = Number(q.value.Quantity.id);
            }
          })
          .filter((x) => x !== undefined)
          .slice(-1)[0];
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      i.input
        .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
        .map((q) => {
          sets = Number(q.value.Quantity.unit);
          repetition = Number(q.value.Quantity.code);
          rest = q.value.Quantity.value;
        })
        .filter((x) => x !== undefined)
        .slice(-1)[0];

      const numberTempo = tempo.split('-').filter((x) => x !== '');

      tempoSecond =
        numberTempo.length !== 0 &&
        Number(numberTempo[0]) +
          Number(numberTempo[1]) +
          Number(numberTempo[2]);

      totalTime +=
        tempoSecond * repetition * sets + rest + durationBasedSeconds;
    });
  });

  return {
    exerciseCount: countOfTask,
    durationOfPlan: Number(totalTime)
  };
};

export const formatExerciseValues = (plan) => {
  let values = [];

  const exerciseCount = plan.map((row) => row.tasks.length);

  plan.map((row) => {
    row.tasks.map((item) => {
      const tempoValue = item.input
        .filter((x) => x.type.coding?.[0].code === 'requestedTempo')
        .slice(-1)[0];

      if (tempoValue?.value.string) {
      } else {
        values.push({
          tempo: item.input[item.input.length - 1].value.Quantity.id,
          sets: item.input[item.input.length - 1].value.Quantity.unit,
          repetitions: item.input[item.input.length - 1].value.Quantity.code,
          rest: item.input[item.input.length - 1].value.Quantity.value
        });
      }
    });
  });

  const durationOfPlan = values.reduce(
    (acc, curr) =>
      acc +
      calculateExerciseTime({
        tempo: curr.tempo,
        repetitions: curr.repetitions,
        sets: curr.sets
      }),
    0
  );

  const durationOfRest = values.reduce((acc, curr) => acc + curr.rest, 0);

  return {
    exerciseCount: Number(exerciseCount),
    durationOfPlan: durationOfPlan
  };
};

export const generateExerciseEditInput = (values: any) => {
  const { exerciseTime, tempoValue, repetetionValue, setsValue, restValue } =
    values;

  return [
    {
      type: {
        coding: [
          {
            code: 'exercise-time',
            system: 'http://extension.actimi.com/exercise-input-type'
          }
        ]
      },
      value: {
        Duration: {
          code: 's',
          unit: 'sec',
          value: exerciseTime,
          system: 'http://unitsofmeasure.org'
        }
      }
    },
    {
      type: {
        coding: [
          {
            code: 'edit-exercise-units',
            system: 'http://hl7.org/financial-taskinputtype'
          }
        ]
      },
      value: {
        Quantity: {
          id: String(tempoValue),
          code: String(repetetionValue),
          unit: String(setsValue),
          value: Number(restValue)
        }
      }
    },
    {
      type: {
        coding: [
          {
            code: 'requestedTempo',
            system: 'http://extension.actimi.com/requestedTempo'
          }
        ]
      },
      value: {
        string: String(tempoValue)
      }
    }
  ];
};

export const filterDefaultTasks = (tasks: Task[], defaultProgram: CarePlan) => {
  const defaultTasksId = defaultProgram?.activity?.map(
    (item) => item.reference.id
  );
  const filteredTasks = tasks?.filter(
    (item) => !defaultTasksId?.includes(item.id)
  );

  return filteredTasks;
};

export const filteredTaskByTaskState = (
  data: Task[],
  taskState: PatientProgramState
) => {
  switch (taskState) {
    case PatientProgramState.CURRENT:
      return data?.filter((item) =>
        moment().isBetween(
          item?.executionPeriod?.start,
          item?.executionPeriod?.end
        )
      );
    case PatientProgramState.PREV:
      return data?.filter(
        (item) => !moment(moment()).isBefore(item?.executionPeriod?.end)
      );
    case PatientProgramState.FUTURE:
      return data?.filter((item) => getFutureTask(item, moment()));
    default:
      return data?.filter((item) =>
        moment(moment()).isBefore(item?.executionPeriod?.end)
      );
  }
};

export const getFutureTask = (task: Task, currentDate) => {
  const taskStartDate = moment(task.executionPeriod.start);
  const taskEndDate = moment(task.executionPeriod.end);
  return (
    taskStartDate.isAfter(currentDate, 'day') &&
    taskEndDate.isAfter(currentDate, 'day')
  );
};

export const calculateDuration = (data: ExerciseData[], t): string => {
  let totalTime = 0;
  data.forEach((i) => {
    let repetition = 0;
    let rest = 0;
    let sets = 0;
    let tempo = '';
    let tempoSecond = 0;
    let durationBasedSeconds = 0;
    const tempoValue = i.currentTask.input
      .filter((x) => x.type.coding?.[0].code === 'requestedTempo')
      .slice(-1)[0];

    if (tempoValue?.value.string) {
      if (tempoValue.value.string.includes('-')) {
        tempo = tempoValue.value.string;
      } else {
        durationBasedSeconds = Number(tempoValue.value.string);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      i.currentTask.input
        .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
        .map((q) => {
          if (q.value.Quantity.id.includes('-')) {
            tempo = q.value.Quantity.id;
          } else {
            durationBasedSeconds = Number(q.value.Quantity.id);
          }
        })
        .filter((x) => x !== undefined)
        .slice(-1)[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    i.currentTask.input
      .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
      .map((q) => {
        sets = Number(q.value.Quantity.unit);
        repetition = Number(q.value.Quantity.code);
        rest = q.value.Quantity.value;
      })
      .filter((x) => x !== undefined)
      .slice(-1)[0];

    const numberTempo = tempo.split('-').filter((x) => x !== '');

    tempoSecond =
      numberTempo.length !== 0 &&
      Number(numberTempo[0]) + Number(numberTempo[1]) + Number(numberTempo[2]);

    totalTime += tempoSecond * repetition * sets + rest + durationBasedSeconds;
  });

  const minutes = Math.floor(totalTime / 60);
  return minutes.toString() + t('min');
};

export const getAccordionTitle = (
  selectedProgramState: PatientProgramState,
  t: TFunction
) => {
  switch (selectedProgramState) {
    case PatientProgramState.PREV:
      return t('Previous Program');

    case PatientProgramState.CURRENT:
      return t('Current Program');

    case PatientProgramState.FUTURE:
      return t('Future Program');

    default:
      return t('Current Program');
  }
};
