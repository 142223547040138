import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Event } from 'src/models/calendar';
import { MeasurementTypeKey } from 'src/models/measure/MeasurementType';
import {
  IGraphicInformation,
  MultipleGraphDataResponse,
  SingleGraphDataResponse
} from 'src/models/graphic/IGraphic';
import { TimeSpan } from 'src/models/observation/enums/TimeSpan';
import {
  getGraphicDataCreatedAtParam,
  handleTimeSpanDateChange
} from 'src/utils/GraphicData';

export interface IGraphicInformationValue {
  readonly values: IValue[];
  readonly marker: string;
  readonly min: number;
  readonly max: number;
  readonly averageOnTime: number;
}

export interface IValue {
  id: string;
  value: number;
  dateTime: Date;
}

declare type IChartDataRecord = Record<MeasurementTypeKey, IGraphicInformation>;
interface MeasurementState {
  events: Event[];
  isDrawerOpen: boolean;
  oxygenSaturation: IGraphicInformation;
  bodyTemperature: IGraphicInformation;
  bloodPressure: IGraphicInformation;
  ecg: IGraphicInformation;
  measurementTypeGraphData:
    | SingleGraphDataResponse
    | MultipleGraphDataResponse
    | undefined;
  allGraphData: IChartDataRecord | undefined;
  isAllGraphDataLoading: boolean;
}

const initialState: MeasurementState = {
  events: [],
  isDrawerOpen: false,
  bloodPressure: {
    values: [],
    name: MeasurementTypeKey.bloodPressure,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  bodyTemperature: {
    values: [],
    name: MeasurementTypeKey.temperature,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  oxygenSaturation: {
    values: [],
    name: MeasurementTypeKey.bloodOxygen,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  ecg: {
    values: [],
    name: MeasurementTypeKey.ecgHRV,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  allGraphData: undefined,
  measurementTypeGraphData: undefined,
  isAllGraphDataLoading: true
};

const slice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {
    fetchGraphicData(
      state: MeasurementState,
      action: PayloadAction<{
        allGraphData: IGraphicInformation[] | undefined;
      }>
    ) {
      const { allGraphData } = action.payload;
      state.allGraphData = allGraphData?.reduce((data, record) => {
        console.assert(
          !(record.name in data),
          `Data for ${record.name} exists more than once in payload!`
        );
        data[record.name] = record;
        return data;
      }, {} as IChartDataRecord);
      state.isAllGraphDataLoading = false;
    },
    fetchGraphicDataByMeasurementType(
      state: MeasurementState,
      action: PayloadAction<{
        measurementTypeGraphData:
          | IGraphicInformation
          | IGraphicInformation[]
          | undefined;
      }>
    ) {
      const { measurementTypeGraphData } = action.payload;
      state.measurementTypeGraphData = measurementTypeGraphData;
      state.isAllGraphDataLoading = false;
    },
    reset(state: MeasurementState) {
      Object.assign(state, initialState);
    }
  }
});

export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchGraphicData =
  (
    patientId: string,
    selectedDateTime: string,
    selectedTimeSpan,
    whenDone: () => void,
    _createdAt?: string
  ): AppThunk =>
  async (dispatch) => {
    let allGraphData;
    const initialIsNext = selectedTimeSpan === TimeSpan.MONTH ? false : true;
    const createdAtByTimeSpan =
      selectedTimeSpan === TimeSpan.MONTH
        ? getGraphicDataCreatedAtParam(
            handleTimeSpanDateChange(
              selectedTimeSpan,
              selectedDateTime,
              initialIsNext
            ),
            selectedDateTime
          )
        : getGraphicDataCreatedAtParam(
            selectedDateTime,
            handleTimeSpanDateChange(
              selectedTimeSpan,
              selectedDateTime,
              initialIsNext
            )
          );
  };

export const fetchGraphicDataByMeasurementType =
  (
    measurementType: MeasurementTypeKey,
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    patientId: string,
    whenDone?: () => void,
    _createdAt?: string,
    userId?: string
  ): AppThunk =>
  async (dispatch) => {
    // const initialIsNext = selectedTimeSpan === TimeSpan.MONTH ? false : true;
    // const createdAtByTimeSpan =
    //   selectedTimeSpan === TimeSpan.MONTH
    //     ? getGraphicDataCreatedAtParam(
    //         handleTimeSpanDateChange(
    //           selectedTimeSpan,
    //           selectedDateTime,
    //           initialIsNext
    //         ),
    //         selectedDateTime
    //       )
    //     : getGraphicDataCreatedAtParam(
    //         selectedDateTime,
    //         handleTimeSpanDateChange(
    //           selectedTimeSpan,
    //           selectedDateTime,
    //           initialIsNext
    //         )
    //       );

    //TODO: Remove union type somehow

    whenDone?.();
  };

export default slice;
