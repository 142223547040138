import React from 'react';
import { experimentalStyled, Typography } from '@mui/material';

interface LabelProps {
  className?: string;
  children?: React.ReactNode;
  sx?: Object;
}

const Label: React.FC<LabelProps> = ({ children, ...rest }) => {
  return (
    <LabelWrapper noWrap color={'#8B92AB'} {...rest}>
      {children}
    </LabelWrapper>
  );
};

export default Label;

const LabelWrapper = experimentalStyled(Typography)(
  () => `
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        background-color: "#FFFFFF";
  `
);
