import type { ReactNode } from 'react';
import {
  ChartSquare,
  Profile2User,
  Messages1,
  ArchiveBook,
  Setting2
} from 'iconsax-react';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Overview',
        icon: () => (
          <ChartSquare
            size={32}
            color={
              window.location.pathname.includes('/dashboards/main-dashboard')
                ? '#26BDD8'
                : '#8B92AB'
            }
          />
        ),
        link: '/dashboards/main-dashboard'
      },
      // {
      //   name: 'Patients',
      //   icon: PeopleAltIcon,
      //   link: '/dashboards/patients'
      // },
      // {
      //   name: 'Patients',
      //   icon: () => (
      //     <Profile2User
      //       size={32}
      //       color={
      //         window.location.pathname.includes('/dashboards/patients')
      //           ? '#26BDD8'
      //           : '#8B92AB'
      //       }
      //     />
      //   ),
      //   link: '/dashboards/patients'
      // },
      // {
      //   name: 'Chat',
      //   icon: () => (
      //     <Messages1
      //       size={32}
      //       color={
      //         window.location.pathname.includes('/applications/messenger')
      //           ? '#26BDD8'
      //           : '#8B92AB'
      //       }
      //     />
      //   ),
      //   link: '/applications/messenger'
      // },
      {
        name: 'Database',
        icon: () => (
          <ArchiveBook
            size={32}
            color={
              window.location.pathname.includes('/dashboards/database')
                ? '#26BDD8'
                : '#8B92AB'
            }
          />
        ),
        link: '/dashboards/database'
      },
      {
        name: 'Settings',
        icon: () => (
          <Setting2
            size={32}
            color={
              window.location.pathname.includes('/dashboards/settings')
                ? '#26BDD8'
                : '#8B92AB'
            }
          />
        ),
        link: '/dashboards/settings'
      }
      // {
      //   name: 'Logs',
      //   icon: LogsIcon,
      //   link: '/applications/log-history'
      // }
      // {
      //   name: 'Notifications',
      //   icon: NotificationsActiveIcon,
      //   link: '/dashboards/notifications'
      // },
      // {
      //   name: "Todo's",
      //   icon: GradingIcon,
      //   link: '/applications/communication-review'
      // },
    ]
  }
];

export default menuItems;
