import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { User } from '../models/user';
import {
  IClinic,
  PatientFactory,
  PatientService
} from '@actimi/core-migration';

export interface AnalyticState {
  isLoading: boolean;
  activeUser: User | undefined;
}

const initialState: AnalyticState = {
  isLoading: true,
  activeUser: undefined
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setActiveUser(
      state: AnalyticState,
      action: PayloadAction<{
        activeUser: User;
      }>
    ) {
      const { activeUser } = action.payload;
      state.activeUser = activeUser;
      state.isLoading = false;
    },
    setIsLoading(
      state: AnalyticState,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) {
      state.isLoading = action.payload.isLoading;
    },
    setContact(
      state: AnalyticState,
      action: PayloadAction<{ contact: User['contact'] }>
    ) {
      const { contact } = action.payload;
      state.activeUser = { ...state.activeUser, contact };
    },
    reset(state: AnalyticState) {
      Object.assign(state, initialState);
    }
  }
});

export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchUser =
  (userId: string, organizationId: string): AppThunk =>
  async (dispatch) => {
    const { getWeightHeight } = new PatientFactory();
    const { getPatient } = new PatientService();
    const bodyValues = await getWeightHeight(userId);
    const patient = await getPatient(userId, organizationId);
    const implicitRules = JSON.parse(patient.implicitRules);
    const user: User = {
      name: {
        given: patient.name?.[0].given[0],
        family: patient.name?.[0].family
      },
      role: ['Patient'],
      email: patient.extension.find((x) => x.id === 'email').valueString,
      resourceType: 'Patient',
      height: `${bodyValues.height} ${implicitRules.height}`,
      weight: `${bodyValues.weight} ${implicitRules.weight}`,
      clinic: { id: patient.managingOrganization.id } as IClinic,
      id: patient.id,
      birthDate: patient.birthDate,
      gender: patient.gender,
      enrollment: patient.meta.createdAt
    };
    dispatch(
      slice.actions.setActiveUser({
        activeUser: user
      })
    );
  };

export const setIsLoading =
  (isLoading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setIsLoading({
        isLoading
      })
    );
  };

export const setActiveUser =
  (activeUser: User): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setActiveUser({
        activeUser
      })
    );
  };

export const setContact =
  (contact: User['contact']): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setContact({ contact }));
  };
export default slice;
