import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Hidden,
  Typography,
  Container,
  Button,
  Stack
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from '../LoginAuth0';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';

import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import LogoMifysio from 'src/components/LogoCardio';
import React from 'react';
import ChangeLanguageBox from '../ChangeLanguageBox';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg',
  telemonitoring: '/static/images/logo/telemonitoring.png',
  mifysioMonitoring: '/static/images/logo/mifysio-monitoring.png'
};

const Content = experimentalStyled(Box)(
  () => `
    display: flex;
    height: 100%;
    flex: 1;
    
`
);

const MainContent = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`
);

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = experimentalStyled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${theme.spacing(6)};
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>Physiomate | {t('Log In')}</title>
      </Helmet>

      <Content>
        <Hidden mdDown>
          <SidebarWrapper>
            <Scrollbars autoHide>
              <SidebarContent>
                <LogoMifysio />

                <Box
                  mb={{
                    sx: 8,
                    md: 8,
                    lg: 6,
                    xl: 20
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ width: 300, marginBottom: 3 }}
                  >
                    <img
                      width={'90%'}
                      alt="Firebase"
                      src={icons['mifysioMonitoring']}
                    />
                  </Box>
                </Box>
              </SidebarContent>
            </Scrollbars>
          </SidebarWrapper>
        </Hidden>
        <Stack mt={2} ml={'87.5%'} position="absolute">
          <ChangeLanguageBox
            isModalOpen={isLanguageModalOpen}
            setIsModalOpen={(val: boolean) => setIsLanguageModalOpen(val)}
          />
        </Stack>
        <MainContent>
          {/* {isLanguageModalOpen && (
            <LanguageModal
              isModalOpen={isLanguageModalOpen}
              setIsModalOpen={(val: boolean) => setIsLanguageModalOpen(val)}
            />
          )} */}

          <Container maxWidth="sm">
            <Card sx={{ p: 4 }}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {t('Log In')}
                </Typography>
              </Box>
              {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />}
              {method === 'Amplify' && <AmplifyLogin />}
              <Box>
                <Button
                  sx={{ mt: 2, height: 48 }}
                  component={RouterLink}
                  to="/account/register-cover"
                  type="submit"
                  fullWidth
                  size="large"
                  variant="outlined"
                >
                  {t('Sign Up')}
                </Button>
              </Box>
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
