export const MeasurementTypeName = {
  bloodOxygen: 'measure.blood-oxygen',
  bloodGlucose: 'measure.blood-glucose',
  dailySteps: 'measure.daily-steps',
  mood: 'measure.mood',
  bloodPressure: {
    title: 'measure.blood-pressure',
    systolic: 'measure.blood-pressure-systolic',
    diastolic: 'measure.blood-pressure-diastolic',
    heartRate: 'measure.blood-pressure-heartRate'
  },
  temperature: 'measure.temperature',
  ecg: 'measure.ecg',
  ecgHrv: 'measure.ecgHrv',
  ecgHeartRate: 'measure.ecgHeartRate',
  bodyWeight: 'measure.body-weight',
  bodyHeight: 'measure.body-height',
  steps: 'measure.daily-steps',
  burnedCalories: 'measure.calories-burned'
};

export enum MeasurementTypeKey {
  bloodOxygen = 'spo2',
  distance = 'distance',
  bloodPressure = 'blood-pressure',
  bloodOxygenPulseRate = 'blood-oxygen-pulse-rate',
  bloodGlucose = 'blood-glucose',
  triglycerides = 'triglycerides',
  uricAcid = 'uric-acid',
  ketone = 'ketone',
  lactate = 'lactate',
  cholesterol = 'cholesterol',
  temperature = 'body-temperature',
  heartRate = 'heartRate',
  ecg = 'ecg',
  ecgHeartRate = 'ecg-heart-rate',
  ecgHRV = 'ecg-hrv',
  bloodPressureDiastolic = 'blood-pressure-diastolic',
  bloodPressureSystolic = 'blood-pressure-systolic',
  bloodPressureHeartRate = 'blood-pressure-heartRate',
  bodyWeight = 'body-weight',
  bodyHeight = 'body-height',
  gratitude = 'gratitude',
  mood = 'mood',
  steps = 'steps',
  pain = 'pain',
  burnedCalories = 'burned-calories',
  dailySteps = 'daily-steps',
  questionnaire = 'symptom-questionnaire',
  painLevel = 'pain-level',
  mfQuestionnaire = 'mf-questionnaire',
  acuteWorkload = 'acute-workload'
}

export enum MeasurementTypeValueKey {
  bloodPressureSystolic = 'mmHg',
  bloodPressureDialostic = 'mmHg',
  bloodPressureHeartRate = 'bpm',
  temperature = '°C',
  spO2 = '%',
  ecgRri = 'ms',
  ecgHeartRate = 'bpm',
  ecgMood = '',
  ecgHrv = 'ms',
  ecgRespiratoryRate = 'bpm',
  bodyWeight = 'kg',
  bodyHeight = 'cm',
  steps = '',
  burnedCalories = 'kcal',
  bloodGlucose = 'mmol/L',
  ketone = 'mmol/L',
  cholesterol = 'mg/dL',
  triglycerides = 'mg/dL',
  uricAcid = 'umol/L',
  lactate = 'mmol/L',
  symptom = 'Symptom(s)',
  acutueWorkload = 'ACWR',
  mfQuestionnaire = 'Avg. Score',
  painLevel = 'VRS'
}

export const MeasurementTypeValueIntervals = {
  temperature: { min: 0, max: 43 },
  bloodOxygen: { max: 100, min: 0 },
  bloodGlucose: { max: 120, min: 70 },
  ecg: { heartRate: { max: 300, min: 0 }, hrv: { max: 300, min: 0 } },
  bloodPressure: {
    diastolic: { max: 300, min: 0 },
    systolic: { max: 300, min: 0 },
    heartRate: { max: 300, min: 0 }
  },
  bodyWeight: { max: 100, min: 65 },
  bodyHeight: { max: 220, min: 50 },
  steps: { max: 10000, min: 4000 },
  burnedCalories: { max: 2000, min: 1200 },
  mood: { max: 5, min: 1 }
};

export const MeasurementTypeDefaultIntervalValues = {
  temperature: { min: 35, max: 43 },
  bloodOxygen: { max: 100, min: 50 },
  bloodGlucose: { max: 120, min: 70 },
  ecg: { heartRate: { max: 300, min: 0 }, hrv: { max: 300, min: 0 } },
  bloodPressure: {
    diastolic: { max: 120, min: 90 },
    systolic: { max: 80, min: 60 },
    heartRate: { max: 90, min: 60 }
  },
  bodyWeight: { max: 100, min: 65 },
  bodyHeight: { max: 195, min: 65 },
  steps: { max: 10000, min: 4000 },
  burnedCalories: { max: 2000, min: 1200 },
  mood: { max: 5, min: 1 },
  dailySteps: { max: 500000, min: 0 },
  symptoms: { max: 6, min: 0 }
};

export const MeasurementTypeFilterIntervalValues = {
  temperature: { max: 43, min: 35 },
  bloodOxygen: { max: 100, min: 90 },
  ecgHeartRate: { max: 110, min: 60 },
  bloodPressure: {
    diastolic: { max: 80, min: 50 },
    systolic: { max: 120, min: 50 },
    heartRate: { max: 110, min: 60 }
  },
  bodyWeight: { max: 100, min: 65 },
  steps: { max: 10000, min: 4000 },
  burnedCalories: { max: 2000, min: 1200 }
};

export const MeasurementTypeFilterDefaultValues = {
  temperature: 36.5,
  bloodOxygen: 95,
  bloodGlucose: 85,
  ecgHeartRate: 90,
  bloodPressure: {
    diastolic: 75,
    systolic: 115,
    heartRate: 90
  },
  bodyWeight: 75,
  bodyHeight: 175,
  steps: 4000,
  burnedCalories: 1200
};

export enum MeasurementTypeDisplayCode {
  oxygenSaturation = 'Blood oxygen in Arterial blood',
  heartRate = 'Heart rate',
  dialosticBloodPressure = 'Diastolic blood pressure',
  systolicBloodPressure = 'Intravascular systolic',
  bodyTemperature = 'Body temperature',
  respiratoryRate = 'Respiratory rate',
  hearthRateVariability = 'Heart rate variability',
  mood = 'Patient Mood',
  ecg = 'Ecg',
  bodyWeight = 'Body weight',
  steps = 'Steps',
  burnedCalories = 'Burned Calories'
}

export const MeasurementClassificationName = {
  bloodPressure: {
    optimal: 'Optimal',
    normal: 'Normal',
    highNormal: 'High-normal',
    grade1HyperTension: 'Grade-1 Hypertension',
    grade2HyperTension: 'Grade-2 Hypertension',
    grade3HyperTension: 'Grade-3 Hypertension',
    isolatedSystolicHyperTension: 'Isolated Systolic Hypertension'
  },
  temperature: {
    hypothermia: 'Hypothermia',
    normal: 'Normal',
    fever: 'Fever',
    highFever: 'High Fever',
    veryHighFever: 'Very High Fever'
  },
  heartRate: {
    low: 'Low',
    lowNormal: 'Low Normal',
    normal: 'Normal',
    high: 'High'
  },
  oxygenSaturation: {
    low: 'Low',
    lowNormal: 'Low Normal',
    normal: 'Normal'
  },
  bodyWeight: {
    underWeight: 'Under Weight',
    normalWeight: 'Normal Weight',
    overWeight: 'Over Weight',
    obese: 'Obese'
  }
};
export const MeasurementClassificationValues = {
  bloodPressure: {
    systolic: {
      optimal: { max: 119, min: 0 },
      normal: { max: 129, min: 120 },
      highNormal: { max: 139, min: 130 },
      grade1HyperTension: { max: 159, min: 140 },
      grade2HyperTension: { max: 179, min: 160 },
      grade3HyperTension: { max: 300, min: 180 },
      isolatedSystolicHyperTension: { max: 300, min: 140 }
    },
    diastolic: {
      optimal: { max: 79, min: 0 },
      normal: { max: 84, min: 80 },
      highNormal: { max: 89, min: 85 },
      grade1HyperTension: { max: 99, min: 90 },
      grade2HyperTension: { max: 109, min: 100 },
      grade3HyperTension: { max: 300, min: 110 },
      isolatedSystolicHyperTension: { max: 300, min: 90 }
    }
  },
  temperature: {
    hypothermia: { min: 0, max: 35 },
    normal: { min: 35.5, max: 37.5 },
    fever: { min: 37.5, max: 39.5 },
    highFever: { min: 39.5, max: 41 },
    veryHighFever: { min: 41, max: 99 }
  },
  heartRate: {
    low: { min: 0, max: 50 },
    lowNormal: { min: 50, max: 60 },
    normal: { min: 60, max: 100 },
    high: { min: 100, max: 999 }
  },
  oxygenSaturation: {
    low: { min: 0, max: 90 },
    lowNormal: { min: 90, max: 95 },
    normal: { min: 95, max: 100 }
  },
  bodyWeight: {
    underWeight: { min: 0, max: 18.5 },
    normalWeight: { min: 24.9, max: 18.5 },
    overWeight: { min: 25, max: 29.9 },
    obese: { min: 30, max: 99 }
  }
};

// export const DefaultRiskScoreValues = {
//   bloodPressure: {
//     systolic: {
//       normal: { min: 0, max: 119 },
//       lowSeverity: { min: 120, max: 129 },
//       mediumSeverity: { min: 130, max: 139 },
//       highSeverity: { min: 140, max: 300 },
//     },
//   },
//   temperature: {
//     normal: { min: 36.5, max: 37.5 },
//     lowSeverity: { min: 37.6, max: 38.5 },
//     mediumSeverity: { min: 38.6, max: 39.5 },
//     highSeverity: { min: 39.6, max: 41.5 },
//   },
//   heartRate: {
//     normal: { min: 60, max: 100 },
//     lowSeverity: { min: 101, max: 120 },
//     mediumSeverity: { min: 121, max: 130 },
//     highSeverity: { min: 130, max: 300 },
//   },
//   bloodOxygen: {
//     normal: { min: 95, max: 100 },
//     lowSeverity: { min: 90, max: 94 },
//     mediumSeverity: { min: 85, max: 90 },
//     highSeverity: { min: 80, max: 89 },
//   },
//   bloodGlucose: {
//     normal: { min: 93.36, max: 99 },
//     lowSeverity: { min: 100, max: 105 },
//     mediumSeverity: { min: 106, max: 125 },
//     highSeverity: { min: 126, max: 900 },
//   },
//   bmi: {
//     normal: { min: 18.5, max: 24.9 },
//     lowSeverity: { min: 25.0, max: 29.9 },
//     mediumSeverity: { min: 30.0, max: 34.9 },
//     highSeverity: { min: 35, max: 100 },
//   },
// };

/*
#define BG_LOW      20.0
#define BG_HIGH     600.0
#define KT_LOW      0.1
#define KT_HIGH     8.0
#define HB_LOW      6.8
#define HB_HIGH     24.0
#define LAC_LOW     0.3
#define LAC_HIGH    23.0
#define UA_LOW      3.0
#define UA_HIGH     20.0
#define CHOL_LOW    100.0
#define CHOL_HIGH   500.0
#define HCT_LOW     0.0
#define HCT_HIGH    70.0*/
