import { TFunction } from 'i18next';
import { CodeableConcept, HumanName } from '@actimi/core-migration';

export type GenericFormObject = Record<string, string | number | Date>;

export function sendChangedFormData(
  previousData: GenericFormObject,
  data: GenericFormObject
): GenericFormObject {
  const changedData: GenericFormObject = {};
  Object.keys(previousData).forEach((key) => {
    if (previousData[key] !== data[key]) {
      Object.assign(changedData, { [key]: data[key] });
    }
  });
  return changedData;
}

export const formatPatientName = (name: string): HumanName[] => {
  const nameParts = name.trim().split(' ');
  if (nameParts.length > 1) {
    return [
      {
        family: nameParts[2],
        given: [
          nameParts[1] !== undefined
            ? nameParts[0] + ' ' + nameParts[1]
            : nameParts[0]
        ]
      }
    ];
  }
  return [
    {
      family: undefined,
      given: [nameParts[0]]
    }
  ];
};

export const formatFirstLetterUpperCase = (str: string) => {
  return str?.substring(0, 1)?.toUpperCase() + str?.substring(1);
};

export const convertSingleBodyPartKeyToString = (
  bodyPart: string,
  t: TFunction
): string => {
  switch (bodyPart) {
    case 'upperLegLeft':
      return t('body-part.left-upper-leg');
    case 'lowerLegLeft':
      return t('body-part.left-lower-leg');
    case 'kneeLeft':
      return t('body-part.left-knee');
    case 'footLeft':
      return t('body-part.left-foot');
    case 'footRight':
      return t('body-part.right-foot');
    case 'lowerLegRight':
      return t('body-part.right-lower-leg');
    case 'upperLegRight':
      return t('body-part.right-upper-leg');
    case 'abdomenLeft':
      return t('body-part.left-abdomen');
    case 'abdomenRight':
      return t('body-part.right-abdomen');
    case 'armRight':
      return t('body-part.right-arm');
    case 'foreArmRight':
      return t('body-part.right-fore-arm');
    case 'handRight':
      return t('body-part.right-hand');
    case 'handLeft':
      return t('body-part.left-hand');
    case 'shoulderRight':
      return t('body-part.right-shoulder');
    case 'chestRight':
      return t('body-part.right-chest');
    case 'chestLeft':
      return t('body-part.left-chest');
    case 'shoulderLeft':
      return t('body-part.left-shoulder');
    case 'armLeft':
      return t('body-part.left-arm');
    case 'foreArmLeft':
      return t('body-part.left-fore-arm');
    case 'neck':
      return t('body-part.neck');
    case 'headRight':
      return t('body-part.right-head');
    case 'headLeft':
      return t('body-part.left-head');
    default:
      return 'unkown';
  }
};

export const convertBodyStringToKey = (
  bodyParts: CodeableConcept[]
): string[] => {
  let convertedString: string[] = [];

  bodyParts?.map((parts) => {
    convertedString.push(parts as string);
  });
  return convertedString;
};
