import React from 'react';
import { Pagination } from '@mui/material';

interface PaginationProps {
  className?: string;
  disabled?: boolean;
  count: number;
  defaultPage?: number;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  sx?: Object;
  page: number;
  shape?: 'rounded' | 'circular';
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
}

const PaginationGroup: React.FC<PaginationProps> = ({ children, ...rest }) => {
  return (
    <Pagination
      sx={{
        '& .MuiPaginationItem-root:hover': {
          background: 'rgba(124, 146, 255, 0.1)'
        },
        '& .MuiPaginationItem-root': {
          fontWeight: 400,
          '&.Mui-selected': {
            background: '#26BDD8',
            color: 'white'
          },
          '&.Mui-selected:hover': {
            background: 'rgba(38, 189, 216,0.8)',
            color: 'white'
          }
        }
      }}
      shape={rest.shape ?? 'rounded'}
      {...rest}
    />
  );
};

export default PaginationGroup;
