import React, { SyntheticEvent } from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Paper
} from '@material-ui/core';
import { ArrowDown2 } from 'iconsax-react';

interface AutoCompleteInputProps {
  className?: string;
  classes?: Object;
  sx?: Object;
  label?: string;
  renderInput: (params: object) => React.ReactNode;
  clearIcon?: React.ReactNode;
  inputValue: string;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  onClose?: (event: React.SyntheticEvent, reason: string) => void;
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => void;
  clearText?: string;
  closeText?: string;
  size?: 'small' | 'medium';
  value?: string | { label: string; value: string };
  disableClearable?: boolean;
  filterOptions?: (options: Array<any>, state: object) => Array<any>;
  options: Array<any>;
  autoComplete?: boolean;
  fullWidth?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  autoHighlight?: boolean;
  autoSelect?: boolean;
  ChipProps?: Object;
  popupIcon?: React.ReactNode;
  componentname?: string;
  groupBy?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({ ...rest }) => {
  return (
    <Autocomplete
      {...rest}
      popupIcon={rest.popupIcon ?? <ArrowDown2 />}
      autoComplete
      componentName={rest.componentname}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            '& .MuiAutocomplete-listbox': {
              '& :hover': {
                backgroundColor: 'rgba(124, 146, 255, 0.10) !important'
              },
              '& .Mui-focused': {
                backgroundColor: 'white'
              }
            }
          }}
          style={{ marginTop: 7, paddingLeft: 10, paddingRight: 10 }}
        >
          {children}
        </Paper>
      )}
      renderOption={(props, option) => {
        return (
          <span {...props} style={{ borderRadius: 6 }}>
            {option.label}
          </span>
        );
      }}
    />
  );
};

export default AutoCompleteInput;
