import React from 'react';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@material-ui/core';

interface CheckboxButtonProps {
  className?: string;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info';
  checked?: boolean;
  defaultChecked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  sx?: Object;
  unCheckedIcon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  inputProps?: Object;
  label?: string;
  boldLabel?: boolean;
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({ ...rest }) => {
  const { t }: { t: any } = useTranslation();
  const UnCheckedIcon = styled('span')(() => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    boxShadow: '0 0 0 1px rgb(16 22 26 / 35%)',
    border: '8px solid white',
    backgroundColor: '#394b59',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#30404d'
    },
    'input:disabled ~ &': {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 20%)',
      background: 'rgba(57,75,89,.1)',
      borderColor: 'rgba(124, 146, 255, 0.10)'
    }
  }));

  const CheckedIcon = styled(UnCheckedIcon)({
    backgroundColor: '#26BDD8',
    outline: 'none',
    boxShadow: '0 0 0 0.7px rgb(16 22 26 / 35%)',
    border: 'none',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#27a4ba'
    },
    'input:disabled ~ &': {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 35%)',
      background: 'rgba(57,75,89,.5)'
    }
  });

  return (
    <>
      {!rest.label ? (
        <Checkbox
          icon={rest.unCheckedIcon ?? <UnCheckedIcon />}
          checkedIcon={rest.checkedIcon ?? <CheckedIcon />}
          {...rest}
        />
      ) : (
        <FormControlLabel
          componentsProps={rest.boldLabel && { typography: { variant: 'h5' } }}
          control={
            <Checkbox
              icon={rest.unCheckedIcon ?? <UnCheckedIcon />}
              checkedIcon={rest.checkedIcon ?? <CheckedIcon />}
              {...rest}
            />
          }
          label={t(rest.label)}
        />
      )}
    </>
  );
};

export default CheckboxButton;
