import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PatientService } from '@actimi/core-migration';
import {
  ErrorMessage,
  SuccessMessage
} from 'src/content/dashboards/Database/utils';
import axiosAidboxInt from 'src/utils/axios';

const useSendInvitation = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { checkEmail } = new PatientService();
  const { t } = useTranslation();

  const sendInvitation = async (
    email,
    patientName,
    patientSurname,
    practitionerId,
    clinicId,
    currentLanguage,
    callback = () => {},
    message = `The invitation code was successfully sent to ${email}.`
  ) => {
    setIsMounted(true);
    try {
      const [isValidEmail, { data: isSentBeforeEmail }] = await Promise.all([
        checkEmail(email),
        axiosAidboxInt.get(
          `/OneTimePassword?receiverEmail=${email}&_count=1&_result=array`
        )
      ]);
      if (isValidEmail) {
        await axiosAidboxInt.post(
          '/user-account/send-registration-deep-link',
          {
            receiverEmail: email,
            practitionerId: practitionerId,
            organizationId: clinicId,
            extra: {
              name: patientName,
              surname: patientSurname
            }
          },
          {
            headers: {
              product: 'mifysio',
              language: currentLanguage
            }
          }
        );
        setIsMounted(false);
        callback();

        SuccessMessage(
          isSentBeforeEmail.length > 0
            ? t('invitation-popup', {
                email
              })
            : message ||
                t('invitation-popup-2', {
                  email
                }),
          'top-center'
        );
      } else {
        setIsMounted(false);
        callback();
        ErrorMessage(t('email-already-in-use'), 'top-center');
      }
    } catch (error) {
      setIsMounted(false);
      ErrorMessage(t('error-send-invitation'), 'top-center');
    }
  };

  return { sendInvitation, isMounted };
};

export default useSendInvitation;
