export enum Languages {
  en = "en_US",
  de = "de_DE",
  tr = "tr_TR",
  ar = "ar_AE",
  es = "es_ES",
  fr = "fr_FR",
  zh = "zh_CH",
}
export enum LanguageCodes {
  en = "en",
  de = "de",
  tr = "tr",
  ar = "ar",
  es = "es",
  fr = "fr",
  zh = "zh",
}
