import { Box, experimentalStyled } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Label from 'src/components/Base/Label';

const FooterWrapper = experimentalStyled(Box)(
  ({ theme }) => `
         display:flex;
         align-items:center;
         position:"fixed",
         bottom:0,
         width:100%;
         left:0;
         padding-top:10px;
         height:80px;
         background: ${theme.sidebar.background};
         box-shadow: ${theme.sidebar.boxShadow};
         padding-left:${theme.spacing(6)};
  `
);
const Footer: React.FC<{}> = () => {
  return (
    <FooterWrapper>
      <Label sx={{ fontSize: 14, color: '#223354' }}>
        © {moment().year()} Physiomate GmbH
      </Label>
    </FooterWrapper>
  );
};

export default Footer;
