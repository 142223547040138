import { Box, CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';

function SuspenseLoader({ ...props }) {
  // useEffect(() => {
  //   NProgress.start();

  //   return () => {
  //     NProgress.done();
  //   };
  // }, []);

  return (
    <Box
      sx={{ width: '100%', height: '100%' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={10}
    >
      <CircularProgress
        size={54}
        disableShrink
        thickness={3}
        color={props.color ?? 'primary'}
      />
    </Box>
  );
}

export default SuspenseLoader;
