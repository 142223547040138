import { combineReducers } from '@reduxjs/toolkit';
import {
  reducer as calendarReducer,
  reset as resetCalendar
} from 'src/slices/calendar';
import {
  reducer as measurementReducer,
  reset as resetMeasurement
} from 'src/slices/measurement';
import {
  reducer as mainDashboardReducer,
  reset as resetMainDashboard
} from 'src/slices/main-dashboard';
import {
  reducer as analyticsReducer,
  reset as resetAnalytics
} from 'src/slices/analytics';
import {
  reducer as patientsProfileReducer,
  reset as resetPatientsProfile
} from '../slices/patients-profile';
import {
  reducer as patientsDetailReducer,
  reset as resetPatientsDetail
} from '../slices/patients-detail';

import {
  reducer as clinicReducer,
  reset as resetClinic
} from '../slices/clinic';

import { reducer as chartReducer, reset as resetChart } from '../slices/chart';

import { reducer as exerciseReducer } from '../slices/exercise';

const rootReducer = combineReducers({
  chart: chartReducer,
  exercise: exerciseReducer,
  calendar: calendarReducer,
  measurement: measurementReducer,
  mainDashboard: mainDashboardReducer,
  analytics: analyticsReducer,
  patientsProfile: patientsProfileReducer,
  clinic: clinicReducer,
  patientsDetail: patientsDetailReducer
});

//TODO: Refactor maybe?
export const resetActions = [
  resetCalendar,
  resetMeasurement,
  resetChart,
  resetMainDashboard,
  resetAnalytics,
  resetPatientsProfile,
  resetClinic,
  resetPatientsDetail
];

export default rootReducer;
