import React, { useContext } from 'react';
import { Card, Container, Box } from '@mui/material';
import SearchInput from 'src/components/Base/SearchInput';
import DropdownSelectBox from 'src/components/Base/DropdownSelectBox';

interface ISearchParams {
  searchText: string;
  filter: 'All Patients' | 'Invited Patients';
}

const DEFAULT_CONTEXT: ISearchParams = {
  searchText: '',
  filter: 'All Patients'
};

const SearchContext = React.createContext(DEFAULT_CONTEXT);

/**
 * This container provides extra filter options addition to default row filtering options
 */
class GridContainer extends React.Component {
  state: ISearchParams = {
    searchText: '',
    filter: 'All Patients'
  };

  render() {
    const { children } = this.props;

    return (
      <Container maxWidth="xl" sx={{ px: 0, pb: 8 }}>
        <Card>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            bgcolor="white"
            columnGap={2}
            py={2}
            px={2}
          >
            <SearchInput
              value={this.state.searchText}
              onChange={(event: any) =>
                this.setState({ searchText: event.target.value })
              }
            />
            <DropdownSelectBox
              value={this.state.filter}
              outlinedDropdown
              onChange={(event: any) =>
                this.setState({ filter: event.target.value })
              }
              dropdownData={[
                { label: 'All Patients', value: 'All Patients' },
                { label: 'Invited Patients', value: 'Invited Patients' }
                // { label: 'For Nurse', value: 'For Nurse' },
                // { label: 'For Doctor', value: 'For Doctor' }
              ]}
            />
          </Box>
          <SearchContext.Provider value={this.state}>
            {children}
          </SearchContext.Provider>
        </Card>
      </Container>
    );
  }
}

export const useGridContainerFilters = () => useContext(SearchContext);

export default GridContainer;
