import { useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Popover,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
import React from 'react';
import { styled } from '@mui/material';
import deFlag from 'src/assets/icons/de.png';
import usFlag from 'src/assets/icons/en.png';
import trFlag from 'src/assets/icons/tr.png';
import internationalization from 'src/i18n/i18n';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 0.5)};
    height: ${theme.spacing(6)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `

        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

function ChangeLanguageBox({ isModalOpen, setIsModalOpen, ...props }) {
  const { t }: { t: any } = useTranslation();

  const navigate = useNavigate();
  const { logout } = useAuth();

  const user = {
    avatar: '/static/images/avatars/1.jpg',
    name: 'Rachael Simons',
    jobtitle: 'Lead Developer'
  };

  const ref = useRef<any>(null);

  const handleOpen = (): void => {
    setIsModalOpen(true);
  };

  const handleClose = (): void => {
    setIsModalOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const { i18n } = useTranslation();
  const getLanguage = i18n.language;

  const [language, setLanguage] = React.useState('');

  React.useEffect(() => {
    setLanguage(getLanguage ?? 'en');
  }, [getLanguage]);

  const switchLanguage = ({ lng }: { lng: any }) => {
    setLanguage(lng);

    internationalization.changeLanguage(lng);
    setIsModalOpen(false);
  };

  const getLanguageFlagComponent = (lng: any) => {
    switch (lng) {
      case 'en':
        return <ImageWrapper alt="English" src={usFlag} />;

      case 'de':
        return <ImageWrapper alt="German" src={deFlag} />;

      default:
        return <ImageWrapper alt="Turkish" src={trFlag} />;
    }
  };

  const getLanguageTextComponent = (lng: any) => {
    switch (lng) {
      case 'en':
        return <Typography color={'MenuText'}>{t('English')}</Typography>;

      case 'de':
        return <Typography color={'MenuText'}>{t('German')}</Typography>;

      default:
        return <Typography color={'MenuText'}>{t('Turkish')}</Typography>;
    }
  };

  const LanguagesList = React.useMemo(
    () => [
      {
        value: 'en',
        label: t('English')
      },
      {
        value: 'de',
        label: t('English')
      },
      {
        value: 'tr',
        label: t('Turkish')
      }
    ],
    [t]
  );

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        {getLanguageFlagComponent(language)}
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>{getLanguageTextComponent(language)}</UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
              color: '#232F48',
              mt: 0.75
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isModalOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
            ml: 1,
            alignItems: 'center'
          }}
          display="flex"
        >
          {getLanguageFlagComponent(language)}

          <UserBoxText>{getLanguageTextComponent(language)}</UserBoxText>
        </MenuUserBox>

        {LanguagesList.filter((x) => x.value !== language).map(
          (item, index) => (
            <React.Fragment>
              <Divider
                sx={{
                  mb: 0
                }}
              />
              <List
                sx={{
                  p: 1
                }}
                component="nav"
              >
                <ListItem
                  onClick={() => {
                    switchLanguage({ lng: item.value });
                  }}
                  button
                >
                  {getLanguageFlagComponent(item.value)}

                  <UserBoxText>
                    {getLanguageTextComponent(item.value)}
                  </UserBoxText>
                </ListItem>
              </List>
            </React.Fragment>
          )
        )}
      </Popover>
    </>
  );
}

export default ChangeLanguageBox;
const ImageWrapper = styled('img')(
  () => `
              width: 30px;
              height: 30px;
              margin: 3px;
              margin-right: 10px;
      `
);
