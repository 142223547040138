/* eslint-disable array-callback-return */
import moment from 'moment';

export enum TimeSpan {
  LATEST = 'Latest',
  TODAY = 'Today',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year'
}
export enum WEEKLY {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}
export enum YEARLY {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December'
}
export enum MONTHLY {
  FIRST_WEEK = '1',
  SECOND_WEEK = '2',
  THIRD_WEEK = '3',
  FOURTH_WEEK = '4'
}

export const emptyTodayArrayGenerator = () => {
  const emptyArr = [];
  for (let index = 0; index <= 23; index++) {
    emptyArr.push({
      marker: index > 9 ? `${index}` : `0${index}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }
  return emptyArr;
};

// export const convertIndexToWeekDay = (marker:string) => {
//   switch (marker) {
//     case value:

//       break;

//     default:
//       break;
//   }
// }

export const emptyWeeklyArrayGenerator = (newCreatedAt?) => {
  if (newCreatedAt) {
    const separeteTime = newCreatedAt.split('&ge');
    let startDate = moment(
      separeteTime && separeteTime[0].split('T0')[0].split('le')[1],
      'YYYY-MM-DD'
    );
    let endDate = moment(
      separeteTime && separeteTime[1].split('T0')[0],
      'YYYY-MM-DD'
    );
    var today = moment().format('YYYY-MM-DD');
    let checkDate = moment(today, 'YYYY-MM-DD');
    const isLatest = checkDate.isBetween(endDate, startDate);
    let emptyArr = [];
    let startDay = moment(today).subtract(7, 'days');
    if (!isLatest) {
      startDay = moment().startOf('week');
    }
    for (let index = 0; index <= 6; index++) {
      emptyArr.push({
        marker: `${startDay.add(1, 'days').format('dddd')}`,
        values: [],
        max: 0,
        min: 0,
        averageOnTime: null
      });
    }
    return emptyArr.reverse();
  }
};
export const emptyWeeklyAcuteArrayGenerator = (newCreatedAt?) => {
  if (newCreatedAt) {
    const separeteTime = newCreatedAt.split('&ge');
    let startDate = moment(
      separeteTime && separeteTime[0].split('T0')[0].split('le')[1],
      'YYYY-MM-DD'
    );
    let endDate = moment(
      separeteTime && separeteTime[1].split('T0')[0],
      'YYYY-MM-DD'
    );
    var today = moment().format('YYYY-MM-DD');
    let checkDate = moment(today, 'YYYY-MM-DD');
    const isLatest = checkDate.isBetween(endDate, startDate);
    let emptyArr = [];
    let startDay = moment(today).subtract(7, 'days');
    if (!isLatest) {
      startDay = moment().startOf('week');
    }
    for (let index = 0; index <= 6; index++) {
      emptyArr.push({
        id: '',
        marker: `${startDay.add(1, 'days').format('dddd')}`,
        dateTime: '',
        value: {
          acwr: null,
          atl: null,
          ctl: null,
          currenttrimpsession: null,
          effortlevel: null,
          sessionTime: null
        },
        name: 'Chronic Workload'
      });
    }
    return emptyArr;
  }
};

export const emptyMonthlyArrayGenerator = (selectedDateTime: string) => {
  const emptyArr = [];
  for (let index = 0; index <= 29; index++) {
    emptyArr.push({
      marker: `${moment(selectedDateTime).subtract(index, 'days').format('D')}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }

  const reversedArr = emptyArr?.reverse();
  return reversedArr;
};

export const emptyMonthlyStiffness = (selectedDateTime: string) => {
  const emptyArr = [];
  let customMarker = ['1', '10', '15', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      marker: x,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0,
      name: 'Stiffness',
      color: 'y'
    });
  });

  return emptyArr;
};

export const emptyMonthlyPain = (selectedDateTime: string) => {
  const emptyArr = [];
  let customMarker = ['1', '10', '20', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      marker: x,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0,
      name: 'Pain',
      color: 'y'
    });
  });

  return emptyArr;
};
export const emptyMonthlyActivity = (selectedDateTime: string) => {
  const emptyArr = [];
  let customMarker = ['1', '10', '20', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      marker: x,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0,
      name: 'Activity',
      color: 'y'
    });
  });

  return emptyArr;
};
export const emptyMonthlyQoL = (selectedDateTime: string) => {
  const emptyArr = [];
  let customMarker = ['1', '10', '20', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      marker: x,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0,
      name: 'Quality of Life',
      color: 'y'
    });
  });

  return emptyArr;
};

export const emptyMonthlyPainLevel = () => {
  const emptyArr = [];
  let customMarker = ['1', '10', '20', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      avarageOnTime: null,
      marker: x,
      max: 0,
      min: 0,
      patientId: '',
      values: []
    });
  });

  return emptyArr;
};
export const emptyMonthlyAcuteWorkload = () => {
  const emptyArr = [];
  let customMarker = ['1', '5', '10', '15', '20', '25', '30'];
  customMarker.map((x) => {
    emptyArr.push({
      avarageOnTime: null,
      marker: x,
      max: 0,
      min: 0,
      patientId: '',
      values: []
    });
  });

  return emptyArr;
};

export const emptyYearlyArrayGenerator = () => {
  const emptyArr = [];
  for (let index = 0; index <= 11; index++) {
    emptyArr.push({
      marker: `${moment().subtract(index, 'months').get('M') + 1}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }

  const reversedArr = emptyArr?.reverse();
  return reversedArr;
};

//SAMPLE ARRAYS
export const DAILY_ARRAY = ['00', '04', '08', '12', '16', '20'];
export const WEEKLY_ARRAY = [
  WEEKLY.MONDAY,
  WEEKLY.TUESDAY,
  WEEKLY.WEDNESDAY,
  WEEKLY.THURSDAY,
  WEEKLY.FRIDAY,
  WEEKLY.SATURDAY,
  WEEKLY.SUNDAY
];
export const MONTHLY_ARRAY = [1, 5, 10, 15, 20, 25, 30];
export const YEARLY_ARRAY = [
  YEARLY.JANUARY,
  YEARLY.FEBRUARY,
  YEARLY.MARCH,
  YEARLY.APRIL,
  YEARLY.MAY,
  YEARLY.JUNE,
  YEARLY.JULY,
  YEARLY.AUGUST,
  YEARLY.SEPTEMBER,
  YEARLY.OCTOBER,
  YEARLY.NOVEMBER,
  YEARLY.DECEMBER
];

export enum WhenNotMeasuredForTimeSpan {
  DAY = 'day(s)',
  WEEK = 'week(s)'
}
