import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { SearchNormal1 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  className?: string;
  disabled?: boolean;
  value?: number | string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (event: object) => void;
  onPress?: () => void;
  autoFocus?: boolean;
  defaultValue?: string | number;
  id?: string;
  name?: string;
  placeholder?: string;
  inputProps?: Object;
  sx?: Object;
  size?: 'medium' | 'small';
  inputRef?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
}

const SearchInput = forwardRef(
  (
    {
      value,
      disabled,
      inputProps,
      onChange,
      onPress,
      setValue
    }: SearchInputProps,
    inputRef
  ) => {
    const WAIT_INTERVAL = 400; //m

    const timeout = React.useRef<NodeJS.Timeout | null>(null);

    function handleChange(e) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onChange(e);
      }, WAIT_INTERVAL);
    }

    const { t } = useTranslation();
    return (
      <TextField
        placeholder={t('Search...')}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchNormal1
                style={{ cursor: 'pointer' }}
                onClick={onPress}
                color={'#8B92AB'}
              />
            </InputAdornment>
          ),
          ...inputProps
        }}
        inputRef={inputRef}
        sx={{
          '& .MuiOutlinedInput-root': {
            color: '#232F48',
            height: '40px',
            width: '220px',
            fontWeight: 500,
            fontSize: 14,
            '& fieldset': {
              borderRadius: 40,
              borderColor: '#8B92AB',
              borderWidth: 1
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1,
              borderColor: `#26BDD8 !important`
            },
            '& ::placeholder': {
              color: '#8B92AB',
              fontWeight: 500,
              fontSize: 15
            }
          }
        }}
        variant="outlined"
      />
    );
  }
);

export default SearchInput;
