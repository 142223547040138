/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from './store';
import { AbilityContext } from './services/casl/AbilityContext';
import { getClinicMembers, getPractitionerInfo } from './slices/clinic';
import { QueryClient, QueryClientProvider } from 'react-query';
import { axiosAidboxInt } from '../src/utils/axios';
import { initAxiosInstance as initAxiosAidboxInstance } from '@actimi/core-migration';
import internationalization from 'src/i18n/i18n';
import i18n from 'src/i18n/i18n';

const App = () => {
  const queryClient = new QueryClient();
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();
  const dispatch = useDispatch();
  const practitioner = useSelector((state) => state.clinic.practitionerInfo);
  const getLanguage = i18n.language;

  useEffect(() => {
    internationalization.changeLanguage(getLanguage);
  }, []);

  useEffect(() => {
    if (auth.user) {
      const userId = window.localStorage.getItem('userId');

      dispatch(getPractitionerInfo(userId, auth?.user?.clinic?.id));

      dispatch(getClinicMembers(auth.user.clinic.id));
    }
  }, [practitioner?.id, auth?.user?.id]);

  initAxiosAidboxInstance(axiosAidboxInt);
  return (
    <>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AbilityContext.abilityCtx.Provider value={AbilityContext.ability}>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              {auth.isInitialized ? (
                <Fragment>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  {content}
                </Fragment>
              ) : (
                <>auth not initialized</>
              )}
            </QueryClientProvider>
          </AbilityContext.abilityCtx.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};
export default App;
