import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ArrowDown2 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

interface DropdownSelectBoxProps {
  value: string | number;
  onChange: (event: SelectChangeEvent) => void;
  size?: 'small' | 'medium';
  dropdownData: Array<{ label: string; value: string | number }>;
  defaultValue?: string;
  outlinedDropdown?: boolean;
}

const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({
  value,
  onChange,
  dropdownData,
  outlinedDropdown,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value}
      onChange={onChange}
      IconComponent={ArrowDown2}
      sx={
        outlinedDropdown
          ? {
              height: '40px',
              width: '206px',
              paddingLeft: 1,
              '& .MuiSelect-icon': {
                marginTop: '3px',
                marginRight: '5px',
                color: '#232F48'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '40px'
              }
            }
          : {
              '& .MuiSelect-icon': {
                marginTop: '1px',
                right: '1px',
                color: '#232F48'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }
      }
      {...rest}
    >
      {dropdownData &&
        dropdownData.map((item) => (
          <MenuItem
            sx={{
              '&.MuiMenuItem-root': {
                borderRadius: '6px',
                '&&:hover': {
                  backgroundColor: 'rgba(124, 146, 255,0.1)'
                },
                '&&.Mui-selected': {
                  backgroundColor: 'rgba(124, 146, 255,0.1)'
                }
              }
            }}
            value={item.value}
          >
            {t(item.label)}
          </MenuItem>
        ))}
    </Select>
  );
};

export default DropdownSelectBox;
