import React, { useState } from 'react';

import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import AutoCompleteInput from 'src/components/Base/AutoCompleteInput';
import TextInput from 'src/components/Base/TextInput';
import { useDispatch } from 'src/store';
import slice from 'src/slices/exercise';

interface Props {
  name: string;
  label: string;
  options: Array<{ value: string; label: string }>;
  required?: boolean;
  width?:
    | number
    | { xs: string; sm: string; md: number; lg: number; xl: number };
  isDisabled?: boolean;
}

export const Select: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  isDisabled,
  width
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>('');
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    label: '',
    value: ''
  });

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, //: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => {
        console.log(
          autoCompleteValue,
          'autoCompleteValuexx',
          options,
          field.value
        );

        field.value &&
          setAutoCompleteValue(
            options.filter((item) => item.value === field.value)[0]
          );

        field.value === 'Isometrics' &&
          dispatch(slice.actions.setIsIsometric(true));

        return (
          <AutoCompleteInput
            componentname={name}
            size="small"
            disabled={isDisabled}
            options={options}
            label={label}
            value={autoCompleteValue}
            disableClearable
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={async (event: any, newValue: any | null) => {
              await setAutoCompleteValue(newValue);
              form.setFieldValue(name, newValue.value as string);
              newValue.value === 'Isometrics'
                ? dispatch(slice.actions.setIsIsometric(true))
                : dispatch(slice.actions.setIsIsometric(false));
            }}
            renderInput={(params: any) => (
              <TextInput
                {...params}
                label={label}
                size="small"
                inputWidth={{ width: width ?? 340 }}
                helperText={
                  meta.value === '' && meta.touched
                    ? t(meta.error)
                    : required
                    ? t('Required *')
                    : ' '
                }
                // sx={{ width: width ?? 340 }}
                error={meta.value === '' && meta.touched ? meta.error : false}
                value={inputValue}
                validationInput
              />
            )}
          />
        );
      }}
    </Field>
  );
};
