import { TFunction } from 'i18next';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AppThunk, AppDispatch } from './../store/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Questionnaire,
  QuestionnaireItem,
  Task,
  QuestionnaireService,
  TrainingService,
  Device,
  TaskService,
  ITaskUpdate,
  CarePlanService,
  CarePlan,
  TaskCode,
  CarePlanActivity
} from '@actimi/core-migration';
import { ExerciseData } from 'src/components/PatientProfile/ExerciseAccordion';
import {
  ErrorMessage,
  generateCarePlan,
  SuccessMessage
} from 'src/content/dashboards/Database/utils';
import { TrainingSchedules } from '@actimi/core-migration/dist/api/services/interfaces/training';
import { GridSelectionModel } from '@mui/x-data-grid';
import { UpdateCarePlanBody } from '@actimi/core-migration/dist/api/services/interfaces/care-plan';
import axiosAidboxInt from 'src/utils/axios';
import { filterDefaultTasks } from 'src/utils/Task';

export interface IExerciseState {
  exerciseQuestionnaire: Questionnaire[];
  videoFile: File;
  videoURI: string;
  loadStatus: boolean;
  exercise: QuestionnaireItem;
  exercises: Questionnaire[];
  refreshData: boolean;
  selectedExercises: Questionnaire[];
  taskData: Task[];
  snapShotVideo: any;
  libraryType: 'Public' | 'Private';
  levelType: string;
  bodyPartType: string;
  seperatedCarePlan: any[];
  movementType: string;
  databaseCategoryType: string;
  instructionsInputs: { service: string }[];
  attentionInputs: { attention: string }[];
  tipInputs: { tip: string }[];
  videoThumb: string;
  selectedExerciseId: string;
  currentTask: ExerciseData[];
  userTool: Device;
  userSchedule: TrainingSchedules[];
  patientPlan: CarePlan[];
  isIsometric: boolean;
  questionnaireTasks: Task[];
  selectedQuestionnaireIds: GridSelectionModel;
  patientCarePlan: CarePlan;
  exerciseBase: 'duration' | 'tempo';
}

const initialState: IExerciseState = {
  exerciseBase: 'tempo',
  refreshData: false,
  selectedExerciseId: undefined,
  taskData: undefined,
  questionnaireTasks: undefined,
  patientCarePlan: undefined,
  selectedQuestionnaireIds: [],
  patientPlan: undefined,
  instructionsInputs: [{ service: '' }, { service: '' }, { service: '' }],
  attentionInputs: [{ attention: '' }],
  tipInputs: [{ tip: '' }],
  snapShotVideo: undefined,
  libraryType: 'Public',
  seperatedCarePlan: [],
  exerciseQuestionnaire: [],
  videoFile: undefined,
  selectedExercises: [],
  videoURI: undefined,
  videoThumb: undefined,
  loadStatus: false,
  exercise: undefined,
  exercises: undefined,
  levelType: undefined,
  currentTask: undefined,
  bodyPartType: undefined,
  movementType: undefined,
  databaseCategoryType: 'Public',
  userSchedule: undefined,
  userTool: undefined,
  isIsometric: false
};

const slice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    setExercise(state, action: PayloadAction<Questionnaire>) {
      state.exerciseQuestionnaire.push(action.payload);
    },
    removeExercise(state, action: PayloadAction<string>) {
      state.exerciseQuestionnaire = state.exerciseQuestionnaire.filter(
        (q) => q.id !== action.payload
      );
    },
    setVideoFile(state, action: PayloadAction<any>) {
      state.videoFile = action.payload;
    },
    setVideoURI(state, action: PayloadAction<string>) {
      state.videoURI = action.payload;
    },
    setVideoThumb(state, action: PayloadAction<string>) {
      state.videoThumb = action.payload;
    },
    takeSnapShotVideo(state, action: PayloadAction<any>) {
      state.snapShotVideo = action.payload;
    },
    setLoadStatus(state, action: PayloadAction<boolean>) {
      state.loadStatus = action.payload;
    },
    // setQuestionnaire(state, action: PayloadAction<Questionnaire>) {
    //   state.exercise = action.payload;
    // }
    setSelectedQuestionnaireIds(
      state,
      action: PayloadAction<GridSelectionModel>
    ) {
      state.selectedQuestionnaireIds = action.payload;
    },
    setExercises(state, action: PayloadAction<Questionnaire[]>) {
      state.exercises = action.payload;
    },
    setSelectedExericseId: (state, action: PayloadAction<string>) => {
      state.selectedExerciseId = action.payload;
    },
    removeExerciseFromSelected(state, action: PayloadAction<string>) {
      state.selectedExercises = state.selectedExercises.filter(
        (item) => item.id !== action.payload
      );
    },
    setSelectedExercises(state, action: PayloadAction<Questionnaire>) {
      state.selectedExercises = [...state.selectedExercises, action.payload];
    },
    setRefreshData(state, action: PayloadAction<boolean>) {
      state.refreshData = action.payload;
    },
    setTaskData(state, action: PayloadAction<Task[]>) {
      if (action.payload.length > 0) {
        state.taskData = action.payload;
      }
    },
    setLibraryType(state, action: PayloadAction<'Public' | 'Private'>) {
      state.libraryType = action.payload;
    },
    setLevelType(state, action: PayloadAction<string>) {
      state.levelType = action.payload;
    },
    setBodyPart(state, action: PayloadAction<string>) {
      state.bodyPartType = action.payload;
    },
    setPatientCarePlan(state, action: PayloadAction<CarePlan[]>) {
      state.patientCarePlan = action.payload?.[0];
    },
    setMovementPart(state, action: PayloadAction<string>) {
      state.movementType = action.payload;
    },
    setDatabaseCategoryType(state, action: PayloadAction<string>) {
      state.databaseCategoryType = action.payload;
    },
    setIsIsometric(state, action: PayloadAction<boolean>) {
      state.isIsometric = action.payload;
    },
    setExerciseBase(state, action: PayloadAction<'duration' | 'tempo'>) {
      state.exerciseBase = action.payload;
    },
    resetFilters(state) {
      // state.libraryType = '';
      state.levelType = '';
      state.bodyPartType = '';
      state.movementType = '';
      state.databaseCategoryType = 'Public';
    },
    setInputsOfInstructions(
      state,
      action: PayloadAction<{ service: string }[]>
    ) {
      state.instructionsInputs = action.payload;
    },
    setInputsOfAttention(
      state,
      action: PayloadAction<{ attention: string }[]>
    ) {
      state.attentionInputs = action.payload;
    },

    setInputsOfTip(state, action: PayloadAction<{ tip: string }[]>) {
      state.tipInputs = action.payload;
    },
    setCurrentTasks(state, action: PayloadAction<ExerciseData[]>) {
      state.currentTask = action.payload;
    },

    setUserTools(state, action: PayloadAction<Device>) {
      state.userTool = action.payload;
    },
    setUserSchedules(state, action: PayloadAction<TrainingSchedules[]>) {
      state.userSchedule = action.payload;
    },
    setPatientCarePlanWithTask(state, action: PayloadAction<any>) {
      if (action.payload.length > 0) {
        state.taskData = action.payload.filter(
          (item) => item?.code?.text === 'mifysio-exercise'
        );

        state.patientPlan = action.payload.filter(
          (item) => item?.resourceType === 'CarePlan'
        );
      }
    },

    setSeperatedCarePlan(state, action: PayloadAction<any>) {
      state.seperatedCarePlan = action.payload;
    },

    setQuestionnaireTasks(state, action: PayloadAction<any>) {
      state.questionnaireTasks = action.payload;
    },
    resetInstructions(state) {
      state.instructionsInputs = [
        { service: '' },
        { service: '' },
        { service: '' }
      ];
      state.attentionInputs = [{ attention: '' }];
      state.tipInputs = [{ tip: '' }];
    },
    resetSelectedExercises(state) {
      state.selectedExercises = [];
    }
  }
});

export const reducer = slice.reducer;

export default slice;

export const getExerciseQuestionnaire =
  (
    exerciseTitle: string,
    language: string,
    userId?: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const questionnaireService = new QuestionnaireService();
    const response = await questionnaireService.getQuestionnaireByTitle(
      exerciseTitle,
      language as 'en' | 'de' | 'tr'
    );

    dispatch(slice.actions.setExercises(response));
    whenDone?.();
  };

export const createExercise =
  (body: Questionnaire, whenDone?: () => void): AppThunk =>
  async () => {
    const { createQuestionnaire } = new QuestionnaireService();

    await createQuestionnaire(body);

    whenDone?.();
  };

export const updateExercise =
  (
    body: Questionnaire,
    publisherId: string,
    t: TFunction,
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const { updateQuestionnaire } = new QuestionnaireService();

    try {
      await updateQuestionnaire(body, publisherId);
      SuccessMessage(t('update-exercise-popup'), 'top-center');
    } catch (e) {
      ErrorMessage(t('not-edit-popup'), 'top-center');
    }

    whenDone?.();
  };

export const getPatientTasks =
  (
    code: TaskCode,
    userId: string,
    organizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { getPatientTaskByCode } = new TaskService();
      const [response, { data: defaultProgram }] = await Promise.all([
        getPatientTaskByCode(code, userId, organizationId),
        //@ts-ignore
        axiosAidboxInt.get(
          `/CarePlan?subject=${userId}&category=mifysio-general-recommendation&_result=array`
        )
      ]);

      const processedTasks = filterDefaultTasks(response, defaultProgram?.[0]);
      dispatch(slice.actions.setTaskData(response));
      whenDone?.();
    } catch (error) {
      console.log(error, 'AERROER');
      ErrorMessage(
        `An Error Occured While Getting Patient Tasks`,
        'top-center'
      );
    }
  };

export const updateTask =
  (
    taskId: string,
    organizationId: string,
    body: ITaskUpdate,
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const { updateTask } = new TaskService();
    await updateTask(taskId, organizationId, body);
    whenDone?.();
  };

export const deleteExercise =
  (
    questionnaireId: string,
    publisherId: string,
    t,
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const { updateQuestionnaireStatus } = new QuestionnaireService();
    try {
      await updateQuestionnaireStatus(questionnaireId, publisherId, 'retired');
      SuccessMessage(t('Exercise deleted succesfully.'), 'top-center');
    } catch (error) {
      ErrorMessage(t('Error appear while deleting exercise'), 'top-center');
    }
    whenDone?.();
  };

export const getUserTools =
  (userId: string, organizationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { getTrainingToolsByUserId } = new TrainingService();
    const response = await getTrainingToolsByUserId(userId, organizationId);

    dispatch(slice.actions.setUserTools(response));
    whenDone?.();
  };

export const getUserSchedule =
  (userId: string, organizationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { getTrainingSchedule } = new TrainingService();
    const response = await getTrainingSchedule(userId);
    dispatch(slice.actions.setUserSchedules(response));
    whenDone?.();
  };

export const getFilteredExercise =
  (parameters: string[], language: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { getFilteredQuestionnaire } = new QuestionnaireService();
    const response = await getFilteredQuestionnaire(
      parameters,
      language as 'en' | 'de' | 'tr'
    );

    dispatch(slice.actions.setExercises(response));
    whenDone?.();
  };
export const createCarePlan =
  (body: CarePlan, whenDone?: () => void): AppThunk =>
  async () => {
    const { createCarePlan } = new CarePlanService();
    try {
      await createCarePlan(body);
    } catch (err) {
      console.log(err);
    }
    whenDone?.();
  };

export const updateCarePlan =
  (
    body: CarePlanActivity[],
    patientId: string,
    practitionerId: string,
    date?: string,
    startDate?: string,
    organizationId?: string,
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const {
      getCarePlanByPatientIdAndCategory,
      putCareCarePlan,
      createCarePlan
    } = new CarePlanService();

    try {
      const currentPlan = await getCarePlanByPatientIdAndCategory(
        patientId,
        'mifysio-general-tracking',
        practitionerId
      );

      if (currentPlan.length !== 0) {
        const planBody: UpdateCarePlanBody = {
          //@ts-ignore
          activity: currentPlan?.[0]?.activity
            ? [...currentPlan?.[0]?.activity, ...body]
            : body
        };

        await putCareCarePlan(planBody, currentPlan?.[0]?.id);
      } else {
        const generateNewPlan = generateCarePlan({
          patientId,
          date,
          startDate,
          practitionerId,
          activities: body
        });

        await createCarePlan(generateNewPlan);
      }
    } catch (err) {
      console.log(err);
    }
    whenDone?.();
  };

export const getCarePlanByPatientId =
  (patientId: string, organiationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { data }: { data: CarePlan[] } = await axiosAidboxInt.get(
      `CarePlan?subject=${patientId}&_result=array`
    );
    const seperatedPlans = data?.map((item) => {
      return {
        id: item?.id,
        activity: item?.activity,
        parsedActivities: item?.activity?.map((x) => {
          return {
            referance: x?.reference,
            instantiatesCanonical: x?.detail?.instantiatesCanonical
          };
        })
      };
    });
    console.log(data, seperatedPlans, 'seperatedPlans');
    dispatch(
      slice.actions.setSeperatedCarePlan(!!seperatedPlans ? seperatedPlans : [])
    );

    whenDone?.();
  };

export const getCarePlanWithTaskByPatientId =
  (patientId: string, organiationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { getCarePlanWithTasksByPatientId } = new CarePlanService();
    const response = await getCarePlanWithTasksByPatientId(
      patientId,
      organiationId
    );

    dispatch(slice.actions.setPatientCarePlanWithTask(response));

    whenDone?.();
  };

export const deleteExerciseFromProgram =
  (
    activityBody: UpdateCarePlanBody,
    carePlanId: string,
    taskId: string,
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const { putCareCarePlan } = new CarePlanService();
    await Promise.all([
      putCareCarePlan(activityBody, carePlanId),
      axiosAidboxInt.delete(`Task?id=${taskId}`)
    ]);

    whenDone?.();
  };
