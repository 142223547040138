import { TimeSpan } from '@actimi/core-migration';
import moment from 'moment';
import {
  IFormattedGraphicData,
  SingleGraphDataResponse
} from 'src/models/graphic/IGraphic';

//Interfaces and Enums
export interface IXAxisData {
  marker: string;
  date: string;
}

export interface IBloodPressureChartResponse {
  readonly systolicData: IFormattedGraphicData[];
  readonly diastolicData: IFormattedGraphicData[];
  readonly xAxisData: IXAxisData[];
  readonly sysAvarageValues: number[];
  readonly diaAvarageValues: number[];
  readonly sysMinMaxValues: IMinMaxColors[];
  readonly diaMinMaxValues: IMinMaxColors[];
  readonly sysMinData: string[];
  readonly diaMinData: string[];
  readonly sysMaxData: string[];
  readonly diaMaxData: string[];
  readonly sysColors: any[];
  readonly diaColors: any[];
  readonly maxValue: number;
  readonly minValue: number;
}

export interface IMeasurementChartResponse {
  readonly filteredData: IFormattedGraphicData[];
  readonly chartMinMaxValues: IMinMaxColors[];
  readonly xAxisData: IXAxisData[];
  readonly chartMinData: string[];
  readonly chartMaxData: string[];
  readonly chartDataColors: any[];
  readonly chartAvarage: number[];
  readonly maxValue: number;
  readonly minValue: number;
}

export interface IMinMaxColors {
  min: string | null;
  max: string | null;
  colors: any | null;
}

export enum PatientTabs {
  LATEST_TRENDS = 'latest-trends',
  WEEKLY_TRENDS = 'weekly-trends',
  MONTHLY_TRENDS = 'monthly-trends',
  YEARLY_TRENDS = 'yearly-trends'
}

export enum PatientProgramState {
  PREV = 'prev',
  CURRENT = 'current',
  FUTURE = 'future'
}

export const PATIENT_TAB_TIME_SPAN = {
  [PatientTabs.LATEST_TRENDS]: TimeSpan.LATEST,
  [PatientTabs.WEEKLY_TRENDS]: TimeSpan.WEEK,
  [PatientTabs.MONTHLY_TRENDS]: TimeSpan.MONTH,
  [PatientTabs.YEARLY_TRENDS]: TimeSpan.YEAR
};

export const checkMeasurementDataUndefinedNull = (
  selectedData: SingleGraphDataResponse
) => {
  if (
    selectedData?.values?.length <= 0 ||
    (selectedData.latestMeasuredValue === null &&
      selectedData.latestMeasuredDateTime === null)
  )
    return true;
  else return false;
};

//Date Time Functions

export const isDateTimeLatest = (
  selectedTimeSpan: TimeSpan,
  selectedDateTime: string
) => {
  switch (selectedTimeSpan) {
    case TimeSpan.YEAR:
      return moment(selectedDateTime).year() === moment().year();
    case TimeSpan.MONTH:
      return (
        moment(selectedDateTime).month() === moment().month() &&
        moment(selectedDateTime).year() === moment().year()
      );
    case TimeSpan.WEEK:
      return (
        moment(selectedDateTime).add(6, 'days').format('L') ===
        moment().format('L')
      );
    default:
      console.error(`Unexpected timeSpan: ${selectedDateTime}`);
      return moment(selectedDateTime).format('ll') === moment().format('ll');
  }
};

export const getDateTime = (timeSpan: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.TODAY:
      return moment().startOf('day').toISOString();
    case TimeSpan.WEEK:
      return moment()
        .utcOffset(0)
        .subtract(6, 'day')
        .endOf('day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
    case TimeSpan.MONTH:
      return moment().startOf('month').toISOString();
    case TimeSpan.YEAR:
      return moment().startOf('year').toISOString();

    case TimeSpan.LATEST:
      return moment().endOf('day').toISOString();
    default:
  }
};
