/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode } from 'react';
import { Box, experimentalStyled } from '@material-ui/core';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

interface CollapsedSidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            padding-left: 98px;
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        
`
);

const CollapsedSidebarLayout: FC<CollapsedSidebarLayoutProps> = ({
  children
}) => {
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>
          <Outlet />
          {!window.location.pathname.includes('/applications/messenger') && (
            <Footer />
          )}
        </MainContent>
      </MainWrapper>
    </>
  );
};

CollapsedSidebarLayout.propTypes = {
  children: PropTypes.node
};

export default CollapsedSidebarLayout;
