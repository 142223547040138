import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function Header({ title }) {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Typography variant="h1" component="h1" sx={{ pb: 1 }}>
        {t("Patients")}
      </Typography>
      {/* <Typography variant="h4" color="text.secondary" fontWeight="normal">
        50 active, 2 deleted
      </Typography> */}
    </>
  );
}

export default Header;
