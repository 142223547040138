import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Base/TextInput';
import { formatFirstLetterUpperCase } from 'src/utils/Form';
import { redirectFieldName } from 'src/models/exercise-plan/ExercisePlan';

interface Props {
  name: string;
  label: string;
  required?: boolean;
  width?:
    | number
    | { xs: string; sm: string; md: string; lg: string; xl: string };
  isHalf?: boolean;
  isDisabledText?: boolean;
  inputType?: string;
  parametersValue?: boolean;
  defaultValue?: string;
  isParamaters?: boolean;
  isExerciseField?: boolean;
}

export const Text: React.FC<Props> = ({
  name,
  label,
  required,
  width,
  isHalf,
  isDisabledText,
  inputType,
  parametersValue,
  isExerciseField,
  defaultValue,
  isParamaters
}) => {
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, //: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => {
        return (
          <>
            <TextInput
              size="small"
              {...field}
              value={
                field.name.startsWith('parameters.movementPattern')
                  ? formatFirstLetterUpperCase(field.value)
                  : isExerciseField
                  ? redirectFieldName(field.name, field.value, t)
                  : field.value ?? ''
              }
              label={label}
              inputHeight={isParamaters && 53}
              defaultValue={defaultValue}
              parametersValue={parametersValue}
              inputType={inputType}
              error={meta.touched && !!meta.error}
              disabled={isDisabledText}
              validationInput
              inputWidth={width ?? 340}
              isHalf={isHalf ? true : false}
              helperText={
                meta.touched && !!meta.error
                  ? t(meta.error)
                  : required || !parametersValue
                  ? t('Required *')
                  : ' '
              }
            />
          </>
        );
      }}
    </Field>
  );
};
